import React, { useState } from 'react'
import LoaderComp from '../../Component/Loader/LoaderComp'

function EditFileData({ selecetedTransaction, notify, setShowEdit }) {
  const authToken = sessionStorage.getItem('AdminId')
  const [editObject, setEditObject] = useState(selecetedTransaction.fileData)
  const [loading, setLoading] = useState(false)
  const handleUpdate = (key, value) => {
    setEditObject((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    // Send `object` to backend
    const data = {
      category: selecetedTransaction.category,
      fileData: editObject,
    }
    if (data.taxPayerId) {
      data.taxPayerId = selecetedTransaction.taxPayerId
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/file-data/${selecetedTransaction.id}`,
      {
        method: 'put',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            notify('success', 'Edit successful')
            setLoading(false)
            setTimeout(() => {
              window.history.go(-1)
            }, 2000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setLoading(false)
        // setFormLoading(false)
      })
  }
  function close(e) {
    e.preventDefault()
    setShowEdit(false)
  }
  return (
    <div className='edit-modal'>
      <div className='top'>
        <h1>Edit File Data</h1>
      </div>
      <div className='form'>
        <form onSubmit={handleSubmit}>
          <div className='dynamic'>
            {Object.entries(editObject).map(([key, value]) => (
              <div className='card' key={key}>
                <p>{key}</p>
                <input
                  type='text'
                  value={value}
                  onChange={(e) => handleUpdate(key, e.target.value)}
                />
              </div>
            ))}
          </div>
          {loading ? (
            <LoaderComp direction={'right'} />
          ) : (
            <div className='button-part'>
              <button className='cancel' onClick={close}>
                Cancel
              </button>
              <button type='submit'>Edit</button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default EditFileData
