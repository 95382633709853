import React, { useState, useEffect } from 'react'
import { BsArrowLeft, BsBuilding } from 'react-icons/bs'
import { AiOutlineUser } from 'react-icons/ai'
import { TbArrowBarLeft, TbArrowBarRight } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import API from '../../../../Utility/API/API'
import { useForm } from 'react-hook-form'

function UserPage({ notify, loginData }) {
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState({})
  const [SideBar, setSideBar] = useState('false')
  const [informationPage, setInformationPage] = useState('personal')
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchUser(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserData(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const onRegister = (formData) => {
    setFormLoading(true)
    if (loginData.role === 'security admin' || loginData.role === 'admin') {
    } else {
      delete formData.isActive
      delete formData.role
    }
    fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        email: formData.email.toLowerCase(),
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', 'User edited successfully')
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='userPage'>
      <div className='left'>
        <div className='top'>
          <div></div>
          <div className='burger'>
            <TbArrowBarLeft onClick={() => setSideBar(!SideBar)} />
          </div>
        </div>
        <div className='back'>
          <div className='back_left'>
            <BsArrowLeft onClick={() => navigate('/dashboard/admin_user')} />
            <h1>Back</h1>
          </div>
        </div>
        <div className='user_details'>
          <div className='user_left'>
            <div className='status'>
              <div className={userData.isActive ? 'Active' : 'Inactive'}>
                <p>{userData.isActive ? 'Active' : 'Inactive'}</p>
              </div>
            </div>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
              alt=''
            />
          </div>
          <div className='user_right'>
            <div className='card'>
              <p>First Name</p>
              <h2>{userData.firstName}</h2>
            </div>

            <div className='card'>
              <p>Last Name</p>
              <h2>{userData.lastName}</h2>
            </div>
            <div className='card'>
              <p>Role</p>
              <h2>{userData.role}</h2>
            </div>
            <div className='card'>
              <p>Status</p>
              <h2>{userData.isActive ? 'Active' : 'Inactive'}</h2>
            </div>
          </div>
        </div>
        <div className='information_page'>
          {informationPage === 'personal' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <AiOutlineUser />
                </div>
                <h2>Personal Information</h2>
              </div>
              <div className='info_wrap'>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>Phone Number</p>
                    <h3>{userData.mobile}</h3>
                  </div>
                  <div className='card'>
                    <p>Email Address</p>
                    <h3>{userData.email}</h3>
                  </div>
                  <div className='card'>
                    <p>Personal Email Address</p>
                    <h3>{userData.personalEmail}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {informationPage === 'employement' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <BsBuilding />
                </div>
                <h2>Employment Information</h2>
              </div>
              <div className='info_wrap'>
                <form className='form' onSubmit={handleSubmit(onRegister)}>
                  <div className='card'>
                    <p>First Name</p>
                    <input
                      type='text'
                      id=''
                      placeholder='First Name'
                      name='firstName'
                      defaultValue={userData.firstName}
                      {...register('firstName', {
                        required: 'First name is required',
                      })}
                    />
                    {errors.firstName && (
                      <h6 className='vError'>{errors.firstName.message}</h6>
                    )}
                  </div>
                  <div className='card'>
                    <p>Last Name</p>
                    <input
                      type='text'
                      id=''
                      placeholder='Last Name'
                      name='lastName'
                      defaultValue={userData.lastName}
                      {...register('lastName', {
                        required: 'Last name is required',
                      })}
                    />
                    {errors.lastName && (
                      <h6 className='vError'>{errors.lastName.message}</h6>
                    )}
                  </div>
                  <div className='card'>
                    <p>Email Address</p>
                    <input
                      type='email'
                      id=''
                      placeholder='Email Address'
                      name='email'
                      defaultValue={userData.email}
                      {...register('email', {
                        required: 'Email is required',
                      })}
                    />
                    {errors.email && (
                      <h6 className='vError'>{errors.email.message}</h6>
                    )}
                  </div>
                  <div className='card'>
                    <p>Personal Email Address</p>
                    <input
                      type='personalEmail'
                      id=''
                      placeholder=' Personal Email Address'
                      name='personalEmail'
                      defaultValue={userData.personalEmail}
                      {...register('personalEmail', {
                        required: 'Personal Email is required',
                      })}
                    />
                    {errors.email && (
                      <h6 className='vError'>{errors.email.message}</h6>
                    )}
                  </div>
                  <div className='card'>
                    <p>Phone Number</p>
                    <input
                      type='tel'
                      id=''
                      placeholder='Phone Number'
                      name='mobile'
                      defaultValue={userData.mobile}
                      {...register('mobile', {
                        required: 'Phone number is required',
                      })}
                    />
                    {errors.mobile && (
                      <h6 className='vError'>{errors.mobile.message}</h6>
                    )}
                  </div>
                  {loginData.role === 'security admin' ||
                  loginData.role === 'admin' ? (
                    <div className='card'>
                      <p>User Role</p>
                      <select
                        id=''
                        name='role'
                        {...register('role', {
                          required: 'Role is required',
                        })}
                      >
                        <option value={userData.role}>{userData.role}</option>
                        {loginData.role === 'security admin' && (
                          <>
                            {userData.role !== 'security admin' && (
                              <option value='security admin'>
                                Security admin
                              </option>
                            )}
                          </>
                        )}

                        {userData.role !== 'general user' && (
                          <option value='general user'>General User</option>
                        )}
                        {userData.role !== 'admin' && (
                          <option value='admin'>Admin</option>
                        )}
                        {userData.role !== 'executive user' && (
                          <option value='executive user'>Executive User</option>
                        )}
                        {userData.role !== 'data admin' && (
                          <option value='data admin'>Data admin</option>
                        )}
                      </select>
                      {errors.role && (
                        <h6 className='vError'>{errors.role.message}</h6>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {loginData.role === 'security admin' ||
                  loginData.role === 'admin' ? (
                    <div className='card'>
                      <p>User Status</p>
                      <select
                        id=''
                        name='isActive'
                        {...register('isActive', {
                          required: 'Status is required',
                        })}
                      >
                        <option value={userData.isActive}>
                          {userData.isActive ? 'Active' : 'Inactive'}
                        </option>
                        {userData.isActive ? (
                          <option value={false}>Inactive</option>
                        ) : (
                          <option value={true}>Active</option>
                        )}
                      </select>
                      {errors.role && (
                        <h6 className='vError'>{errors.role.message}</h6>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {formLoading ? (
                    <div className='loaders'>
                      <img src={Loader} alt='' />
                    </div>
                  ) : (
                    <div className='buttons'>
                      <button className='save'>Save</button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={SideBar ? 'right' : 'right active'}>
        <div className='close'>
          <TbArrowBarRight onClick={() => setSideBar(!SideBar)} />
        </div>
        <div className='tag'>
          <h1>More Information</h1>
        </div>
        <div className='links'>
          <ul>
            <li
              className={informationPage === 'personal' ? 'active' : ''}
              onClick={() => setInformationPage('personal')}
            >
              Personal Information
            </li>
            <li
              className={informationPage === 'employement' ? 'active' : ''}
              onClick={() => setInformationPage('employement')}
            >
              Edit information
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default UserPage
