import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
} from '@react-pdf/renderer'
import { useParams } from 'react-router-dom'
import ReceiptData from './ReceiptData'

// Define the styles
const styles = StyleSheet.create({
  page: {
    fontSize: 7,
    padding: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  section: {
    marginBottom: 10,
  },
  sectionBorder: {
    borderLeft: '2px solid #000',
    paddingLeft: 5,
    paddingBottom: '5px',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    marginBottom: 7,
  },
  pText: {
    marginBottom: 2,
    textAlign: 'center',
  },
  signature: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightText: {
    textAlign: 'right',
    marginBottom: '10px',
  },
  textBorderBottom: {
    paddingTop: '15px',
    paddingBottom: '20px',
    borderBottom: '2px solid #000',
    marginBottom: '10px',
  },
  flexSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  image: {
    width: '60px',
  },
  move: {
    marginLeft: '50px',
  },
  push: {
    marginRight: '150px',
  },
  receiptContainer: {
    width: '44%',
    padding: 10,
  },
  receiptContainerBottom: {
    width: '44%',
    padding: 10,
    marginBottom: '50px',
  },
  receiptContainerLeft: {
    width: '44%',
    padding: 10,
    marginLeft: '90px',
  },
  pFlex: {
    display: 'flex',
    justifyContent: 'center',
  },
})

// Create the document component
const ReceiptDocument = ({ data }) => (
  <Document>
    <Page style={styles.page} orientation='landscape'>
      <View style={styles.receiptContainerBottom}>
        <View style={styles.rightText}>
          <Text style={styles.text}>Printed date: {data.printed_date}</Text>
        </View>
        <View style={styles.flexSpace}>
          <Text style={styles.text}>File Number: {data.file_number}</Text>
          <Text style={styles.text}>NGN {data.amount}</Text>
        </View>
        <View style={styles.flexSpace}>
          <View style={styles.section}>
            <Text style={styles.text}>Received From: {data.received_from}</Text>
            <Text style={styles.text}>Address: {data.address}</Text>
            <Text style={styles.text}>The Sum of: {data.the_sum_of}</Text>
            <Text style={styles.text}>Being: {data.being}</Text>
            <View style={styles.flexSpace}>
              <View>
                <Text style={styles.text}>Signature or Mark of Payer:</Text>
                <Text style={styles.text}>Teller No: {data.teller_no}</Text>
                <Text style={styles.text}>Bank: {data.bank}</Text>
                <Text style={styles.text}>Receipt No: {data.receipt_no}</Text>
              </View>
              <View style={styles.move}>
                <Text style={styles.text}>
                  Period Ending: {data.period_ending}
                </Text>
                <Image
                  style={styles.image}
                  src={
                    'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png'
                  }
                />
                <Text>Signature of Collector:</Text>
                <Text style={styles.text}>
                  Payment Date: {data.payment_date}
                </Text>
              </View>
            </View>
            <View></View>
          </View>
          <View style={styles.sectionBorder}>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.head_no}
            </Text>
            <Text style={styles.text}>Head No: </Text>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.sub_head_no}
            </Text>
            <Text style={styles.text}>Sub-Head No:</Text>
          </View>
        </View>
        <View style={{ textAlign: 'center' }}>
          <Text style={styles.pText}>
            Please validate this receipt at http://portal.ondostate.gov.ng/
          </Text>
          <Text style={styles.pText}>
            To Verify this receipt, send receipt followed by receipt number to
            any of the following numbers;
          </Text>
          <Text style={styles.pText}>
            09060005389,09060005387,09036092633 e.g receipt 1234
          </Text>
        </View>
      </View>
      <View style={styles.receiptContainerLeft}>
        <View style={styles.rightText}>
          <Text style={styles.text}>Printed date: {data.printed_date}</Text>
        </View>
        <View style={styles.flexSpace}>
          <Text style={styles.text}>File Number: {data.file_number}</Text>
          <Text style={styles.text}>NGN {data.amount}</Text>
        </View>
        <View style={styles.flexSpace}>
          <View style={styles.section}>
            <Text style={styles.text}>Received From: {data.received_from}</Text>
            <Text style={styles.text}>Address: {data.address}</Text>
            <Text style={styles.text}>The Sum of: {data.the_sum_of}</Text>
            <Text style={styles.text}>Being: {data.being}</Text>
            <View style={styles.flexSpace}>
              <View>
                <Text style={styles.text}>Signature or Mark of Payer:</Text>
                <Text style={styles.text}>Teller No: {data.teller_no}</Text>
                <Text style={styles.text}>Bank: {data.bank}</Text>
                <Text style={styles.text}>Receipt No: {data.receipt_no}</Text>
              </View>
              <View style={styles.move}>
                <Text style={styles.text}>
                  Period Ending: {data.period_ending}
                </Text>
                <Image
                  style={styles.image}
                  src={
                    'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png'
                  }
                />
                <Text>Signature of Collector:</Text>
                <Text style={styles.text}>
                  Payment Date: {data.payment_date}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.sectionBorder}>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.head_no}
            </Text>
            <Text style={styles.text}>Head No: </Text>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.sub_head_no}
            </Text>
            <Text style={styles.text}>Sub-Head No:</Text>
          </View>
        </View>
        <View style={{ textAlign: 'center' }}>
          <Text style={styles.pText}>
            Please validate this receipt at http://portal.ondostate.gov.ng/
          </Text>
          <Text style={styles.pText}>
            To Verify this receipt, send receipt followed by receipt number to
            any of the following numbers;
          </Text>
          <Text style={styles.pText}>
            09060005389,09060005387,09036092633 e.g receipt 1234
          </Text>
        </View>
      </View>
      <View style={styles.receiptContainer}>
        <View style={styles.rightText}>
          <Text style={styles.text}>Printed date: {data.printed_date}</Text>
        </View>
        <View style={styles.flexSpace}>
          <Text style={styles.text}>File Number: {data.file_number}</Text>
          <Text style={styles.text}>NGN {data.amount}</Text>
        </View>
        <View style={styles.flexSpace}>
          <View style={styles.section}>
            <Text style={styles.text}>Received From: {data.received_from}</Text>
            <Text style={styles.text}>Address: {data.address}</Text>
            <Text style={styles.text}>The Sum of: {data.the_sum_of}</Text>
            <Text style={styles.text}>Being: {data.being}</Text>
            <View style={styles.flexSpace}>
              <View>
                <Text style={styles.text}>Signature or Mark of Payer:</Text>
                <Text style={styles.text}>Teller No: {data.teller_no}</Text>
                <Text style={styles.text}>Bank: {data.bank}</Text>
                <Text style={styles.text}>Receipt No: {data.receipt_no}</Text>
              </View>
              <View style={styles.move}>
                <Text style={styles.text}>
                  Period Ending: {data.period_ending}
                </Text>
                <Image
                  style={styles.image}
                  src={
                    'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png'
                  }
                />
                <Text>Signature of Collector:</Text>
                <Text style={styles.text}>
                  Payment Date: {data.payment_date}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.sectionBorder}>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.head_no}
            </Text>
            <Text style={styles.text}>Head No: </Text>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.sub_head_no}
            </Text>
            <Text style={styles.text}>Sub-Head No:</Text>
          </View>
        </View>
        <View style={{ textAlign: 'center' }}>
          <Text style={styles.pText}>
            Please validate this receipt at http://portal.ondostate.gov.ng/
          </Text>
          <Text style={styles.pText}>
            To Verify this receipt, send receipt followed by receipt number to
            any of the following numbers;
          </Text>
          <Text style={styles.pText}>
            09060005389,09060005387,09036092633 e.g receipt 1234
          </Text>
        </View>
      </View>
      <View style={styles.receiptContainerLeft}>
        <View style={styles.rightText}>
          <Text style={styles.text}>Printed date: {data.printed_date}</Text>
        </View>
        <View style={styles.flexSpace}>
          <Text style={styles.text}>File Number: {data.file_number}</Text>
          <Text style={styles.text}>NGN {data.amount}</Text>
        </View>
        <View style={styles.flexSpace}>
          <View style={styles.section}>
            <Text style={styles.text}>Received From: {data.received_from}</Text>
            <Text style={styles.text}>Address: {data.address}</Text>
            <Text style={styles.text}>The Sum of: {data.the_sum_of}</Text>
            <Text style={styles.text}>Being: {data.being}</Text>
            <View style={styles.flexSpace}>
              <View>
                <Text style={styles.text}>Signature or Mark of Payer:</Text>
                <Text style={styles.text}>Teller No: {data.teller_no}</Text>
                <Text style={styles.text}>Bank: {data.bank}</Text>
                <Text style={styles.text}>Receipt No: {data.receipt_no}</Text>
              </View>
              <View style={styles.move}>
                <Text style={styles.text}>
                  Period Ending: {data.period_ending}
                </Text>
                <Image
                  style={styles.image}
                  src={
                    'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png'
                  }
                />
                <Text>Signature of Collector:</Text>
                <Text style={styles.text}>
                  Payment Date: {data.payment_date}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.sectionBorder}>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.head_no}
            </Text>
            <Text style={styles.text}>Head No: </Text>
            <Text style={(styles.text, styles.textBorderBottom)}>
              {data.sub_head_no}
            </Text>
            <Text style={styles.text}>Sub-Head No:</Text>
          </View>
        </View>
        <View style={styles.pFlex}>
          <Text style={styles.pText}>
            Please validate this receipt at http://portal.ondostate.gov.ng/
          </Text>
          <Text style={styles.pText}>
            To Verify this receipt, send receipt followed by receipt number to
            any of the following numbers;
          </Text>
          <Text style={styles.pText}>
            09060005389,09060005387,09036092633 e.g receipt 1234
          </Text>
        </View>
      </View>
    </Page>
  </Document>
)

// Example data object
const data = {
  file_number: '2071939',
  received_from: 'ALONGE AYOMIDE EMMANUEL',
  address: 'abuja',
  amount: '5,000.00',
  the_sum_of: 'FIVE THOUSAND NIGERIAN NAIRA ONLY',
  being: 'FEES - GENERAL-STATE OF ORIGIN CERTIFICATE',
  signature_or_mark_of_payer: 'ALONGE',
  teller_no: '0508327',
  bank: 'WEMA Bank Plc',
  receipt_no: '1418442',
  printed_date: '29-05-2024',
  period_ending: '11102100200_8662',
  head_no: '11102100200',
  payment_date: '14-06-2023',
  sub_head_no: '12020494',
  signature_or_mark_of_collector: 'Ayobami',
}

const ReceiptPdf = () => {
  const { id } = useParams()
  const [receipt, setReceipt] = useState({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const foundData = ReceiptData.find((item) => item.receipt_no === id)
    if (foundData) {
      setReceipt(foundData)
      setLoading(false)
    }
  }, [id, receipt])

  return loading ? (
    'Loading.....'
  ) : (
    <div className='taxtransaction'>
      <div className='receipt_top'>
        <h1>Receipt PDF</h1>
        <PDFDownloadLink
          document={<ReceiptDocument data={receipt} />}
          fileName={`${receipt.received_from + receipt.receipt_no}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : <button>Download</button>
          }
        </PDFDownloadLink>
      </div>
      <PDFViewer style={{ width: '100%', height: '80vh', marginTop: '' }}>
        {<ReceiptDocument data={receipt} />}
      </PDFViewer>
    </div>
  )
}

export default ReceiptPdf
