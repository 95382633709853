import React, { useState, useEffect } from 'react'
import './UserPage.scss'
import { BsArrowLeft, BsBuilding, BsFillMegaphoneFill } from 'react-icons/bs'
import { AiOutlineUser } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { MdOutlineHouse, MdOutlineFamilyRestroom } from 'react-icons/md'
import { TbArrowBarLeft, TbArrowBarRight, TbReceiptTax } from 'react-icons/tb'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import { useNavigate, useParams, Link } from 'react-router-dom'
import API from '../../../../Utility/API/API'
import { useForm } from 'react-hook-form'

function UserPage({ notify, IsUpload, IsEdit, fixVIP }) {
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const { id } = useParams()
  const [SideBar, setSideBar] = useState('false')
  const [informationPage, setInformationPage] = useState('personal')
  const { handleDataModalToggle, handleAddSocialToggle } = useGlobalContext()
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const [taxPayerData, setTaxPayerData] = useState({
    residency: {
      address: '',
      city: '',
      country: '',
      lga: '',
      nearestBusStop: '',
      numberOfNeighbours: 0,
      residentType: '',
      state: '',
      yearsOfStay: 0,
    },
    spouse: {
      firstName: '',
      lastName: '',
      tin: '',
      dateOfBirth: '',
      employerName: '',
    },
    children: [],
  })
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onEnroll = (formData) => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tax-payers/${taxPayerData.id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        isVip: formData.isVip,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax payer Edited successful`)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)

            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  useEffect(() => {
    API.FetchTaxPayer(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayerData(data)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])

  /// Linked Data
  const [linkedTab, setLinkedTab] = useState('ettc')
  const [linkLoading, setLinkLoading] = useState(true)
  const [linkedData, setLinkedData] = useState({})
  const FetchLinkedData = (type, page) => {
    setLinkLoading(true)
    API.FindLinkedData(id, type, page).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLinkLoading(false)
          setLinkedData(data)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  return (
    <div className='userPage'>
      <div className='left'>
        <div className='top'>
          {IsUpload ? (
            <button onClick={() => handleDataModalToggle()}>
              Add New Payer
            </button>
          ) : (
            <div></div>
          )}

          <div className='burger'>
            <TbArrowBarLeft onClick={() => setSideBar(!SideBar)} />
          </div>
        </div>
        <div className='back'>
          <div className='back_left'>
            <BsArrowLeft onClick={() => navigate(-1)} />
            <h1>
              {taxPayerData.firstName} {taxPayerData.lastName}
            </h1>
          </div>
          <div className='back_right'>
            <p>Enrollee ID: #{taxPayerData.enrolleeId}</p>
          </div>
        </div>
        <div className='user_details'>
          <div className='user_left'>
            <div className='status'>
              <div className='Active'>
                <p>Active</p>
              </div>
            </div>
            <img
              src={
                Object.keys(taxPayerData).includes('profilePicture')
                  ? taxPayerData.profilePicture
                  : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
              }
              alt=''
            />
          </div>
          <div className='user_right'>
            <div className='card'>
              <p>First Name</p>
              <h2>{taxPayerData.firstName}</h2>
            </div>
            <div className='card'>
              <p>Middle Name</p>
              <h2>{taxPayerData.middleName}</h2>
            </div>
            <div className='card'>
              <p>Last Name</p>
              <h2>{taxPayerData.lastName}</h2>
            </div>
            <div className='card'>
              <p>Date of Birth</p>
              <h2>{new Date(taxPayerData.dateOfBirth).toDateString()}</h2>
            </div>
            <div className='card'>
              <p>Gender</p>
              <h2>{taxPayerData.gender}</h2>
            </div>
            <div className='card'>
              <p>Marital Status</p>
              <h2>{taxPayerData.maritalStatus}</h2>
            </div>
          </div>
        </div>
        <div className='information_page'>
          {informationPage === 'personal' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <AiOutlineUser />
                </div>
                <h2>Personal Information</h2>
              </div>
              <div className='info_wrap'>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>City</p>
                    <h3>{taxPayerData.residency.city}</h3>
                  </div>
                  <div className='card'>
                    <p>State</p>
                    <h3>{taxPayerData.residency.state} State</h3>
                  </div>
                  <div className='card'>
                    <p>Local Govt Area</p>
                    <h3>{taxPayerData.residency.lga}</h3>
                  </div>
                </div>
                <div className='single_wrap'>
                  <p>Residential Address</p>
                  <h3>{taxPayerData.residency.address}</h3>
                </div>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>Phone Number</p>
                    <h3>{taxPayerData.phone}</h3>
                  </div>
                  <div className='card'>
                    <p>Email Address</p>
                    <h3>{taxPayerData.email}</h3>
                  </div>
                  <div className='card'>
                    <p>NIN</p>
                    <h3>{taxPayerData.nin}</h3>
                  </div>
                  <div className='card'>
                    <p>TIN</p>
                    <h3>{taxPayerData.tin}</h3>
                  </div>
                  <div className='card'>
                    <p>Odtin</p>
                    <h3>{taxPayerData.odTin}</h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {informationPage === 'employement' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <BsBuilding />
                </div>
                <h2>Employment Information</h2>
              </div>
              <div className='info_wrap'>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>Business Name</p>
                    <h3>Dennis and Sons Furniture</h3>
                  </div>
                  <div className='card'>
                    <p>Industry</p>
                    <h3>Furniture</h3>
                  </div>
                  <div className='card'>
                    <p>Number of Employee</p>
                    <h3>Eight (8)</h3>
                  </div>
                </div>
                <div className='single_wrap'>
                  <p>Business Adderess</p>
                  <h3>
                    34a Ago Street Toyege Junction, Agric Bus-stop, Ikorodu
                    Road, Lagos Nigeria.
                  </h3>
                </div>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>Position</p>
                    <h3>Manager</h3>
                  </div>
                  <div className='card'>
                    <p>Designation</p>
                    <h3>Product Designer</h3>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {informationPage === 'residency' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <MdOutlineHouse />
                </div>
                <h2>Residency Status</h2>
              </div>
              <div className='info_wrap'>
                <div className='single_wrap'>
                  <p>Full Residential Address</p>
                  <h3>
                    {taxPayerData.residency.address},
                    {taxPayerData.residency.city},{' '}
                    {taxPayerData.residency.state} state,{' '}
                    {taxPayerData.residency.country}.
                  </h3>
                </div>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>State </p>
                    <h3>{taxPayerData.residency.state}</h3>
                  </div>
                  <div className='card'>
                    <p>LGA</p>
                    <h3>{taxPayerData.residency.lga} LGA</h3>
                  </div>
                  <div className='card'>
                    <p>Nearest B/Stop</p>
                    <h3>{taxPayerData.residency.nearestBusStop}</h3>
                  </div>
                  <div className='card'>
                    <p>Country</p>
                    <h3>{taxPayerData.residency.country}</h3>
                  </div>
                </div>

                <div className='four_wrap'>
                  <div className='card'>
                    <p>Type of Resident</p>
                    <h3>{taxPayerData.residency.residentType}</h3>
                  </div>
                  <div className='card'>
                    <p>Years of Stay</p>
                    <h3>{taxPayerData.residency.yearsOfStay} Years</h3>
                  </div>
                  <div className='card'>
                    <p>Number of Neighbour</p>
                    <h3>
                      {taxPayerData.residency.numberOfNeighbours} Neighbour
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          {informationPage === 'spouse' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <MdOutlineFamilyRestroom />
                </div>
                <h2>Spouse and Kids Information</h2>
              </div>
              <div className='info_wrap'>
                {Object.keys(taxPayerData).includes('spouse') &&
                Object.keys(taxPayerData.spouse).length > 0 ? (
                  <div className='four_wrap'>
                    <div className='card'>
                      <p>Spouse First Name </p>
                      <h3>{taxPayerData.spouse.firstName}</h3>
                    </div>
                    <div className='card'>
                      <p>Spouse Last Name</p>
                      <h3>{taxPayerData.spouse.lastName}</h3>
                    </div>
                    <div className='card'>
                      <p>Spouse NIN</p>
                      <h3>{taxPayerData.spouse.tin}</h3>
                    </div>
                    <div className='card'>
                      <p>Date of Birth</p>
                      <h3>
                        {new Date(
                          taxPayerData.spouse.dateOfBirth
                        ).toDateString()}
                      </h3>
                    </div>
                    <div className='card'>
                      <p>Number of Children</p>
                      <h3>{taxPayerData.children.length}</h3>
                    </div>
                  </div>
                ) : (
                  <h3>Spouse details not uploaded</h3>
                )}

                <div className='four_wrap'>
                  {Object.keys(taxPayerData).includes('children') && (
                    <>
                      {taxPayerData.children.map((item, i) => (
                        <div className='card' key={1 + i}>
                          <p>Child {i + 1} </p>
                          <h3>
                            {item.firstName} {item.lastName}
                          </h3>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {informationPage === 'tax' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'>
                    <div className='icon'>
                      <TbReceiptTax />
                    </div>
                    <h2>TAX Information</h2>
                  </div>
                  <div className='two'>
                    <button className='active'>Income tax</button>
                    <button>LUC</button>
                    <button>Capital Gains</button>
                  </div>
                </div>
              </div>
              <div className='info-table'>
                <table>
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Tax paid</th>
                      <th>Tax Period</th>
                      <th>Open Cases</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(3)].map((item) => (
                      <tr>
                        <td>January</td>
                        <td>NGN 23,746.07</td>
                        <td>3rd February</td>
                        <td>0 Case</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {informationPage === 'assets' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'></div>
                  <div className='two'>
                    <button className='active'>Vehicle</button>
                  </div>
                </div>
              </div>
              <div className='info-table'>
                <table>
                  <thead>
                    <tr>
                      <th>APPLICATION ID</th>
                      <th>ENGINE NO</th>
                      <th>CHASIS NO</th>
                      <th>CATEGORY</th>
                      <th>MAKE</th>
                      <th>MODEL</th>
                      <th>TYPE</th>
                      <th>COLOR</th>
                      <th>PURPOSE</th>
                      <th>CAPACITY</th>
                      <th>PLATE NUMBER</th>
                      <th>OWNER'S NAME</th>
                      <th>Phone</th>
                      <th>ADDRESS</th>
                      <th>LGA</th>
                      <th>DATE</th>
                      <th>REG DESCRIPTION</th>
                      <th>YEAR OF MANUFACTURE</th>
                      <th>OFFICER</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(taxPayerData).includes('assets') && (
                      <>
                        {taxPayerData.assets.map((item) => (
                          <tr key={item.id}>
                            <td>{item.applicationId}</td>
                            <td>{item.engineNumber}</td>
                            <td>{item.chasisNumber}</td>
                            <td>{item.category}</td>
                            <td>{item.make}</td>
                            <td>{item.model} </td>
                            <td>{item.type}</td>
                            <td>{item.color}</td>
                            <td>{item.purpose}</td>
                            <td>{item.capacity}</td>
                            <td>{item.plateNumber}</td>
                            <td>{item.name}</td>
                            <td>{item.phone}</td>
                            <td>{item.address}</td>
                            <td>{item.lga}</td>
                            <td>
                              {new Date(item.dateOfPurchase).toDateString()}
                            </td>
                            <td>{item.description}</td>
                            <td>{item.year}</td>
                            <td>{item.officer}</td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {informationPage === 'linked' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'></div>
                  <div className='two'>
                    <button
                      onClick={() => {
                        setLinkedTab('ettc')
                        FetchLinkedData('e-tax', 1)
                      }}
                      className={linkedTab === 'ettc' ? 'active' : ''}
                    >
                      E-Tax
                    </button>
                    <button
                      onClick={() => {
                        setLinkedTab('tax-transactions')
                        FetchLinkedData('tax-transactions', 1)
                      }}
                      className={
                        linkedTab === 'tax-transactions' ? 'active' : ''
                      }
                    >
                      Tax transactions
                    </button>
                    <button
                      onClick={() => {
                        setLinkedTab('file-data')
                        FetchLinkedData('file-data', 1)
                      }}
                      className={linkedTab === 'file-data' ? 'active' : ''}
                    >
                      General Data
                    </button>
                    <button
                      onClick={() => {
                        setLinkedTab('corporate')
                        FetchLinkedData('employer', 1)
                      }}
                      className={linkedTab === 'corporate' ? 'active' : ''}
                    >
                      Corporate
                    </button>
                  </div>
                </div>
              </div>
              <div className='info-table'>
                {linkedTab === 'ettc' && (
                  <>
                    {linkLoading ? (
                      'Loading...'
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>File Name</th>
                            <th>Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          {linkedData.results.map((item) => (
                            <tr key={item.id}>
                              <td>{item.fileName}</td>
                              <td>
                                <a
                                  href={`${process.env.REACT_APP_API_URL}/media/${item.path}`}
                                >
                                  <p>{item.fullPath}</p>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
                {linkedTab === 'tax-transactions' && (
                  <>
                    {linkLoading ? (
                      'Loading...'
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Payer Name</th>
                            <th>Amount</th>
                            <th>Payment</th>
                            <th>MDA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {linkedData.results.map((item) => (
                            <tr
                              key={item.id}
                              onClick={() =>
                                navigate(`/dashboard/receipt/${item.id}`)
                              }
                            >
                              <td>{item.payerName}</td>
                              <td>
                                <p>
                                  # {parseFloat(item.amount).toLocaleString()}
                                </p>
                              </td>
                              <td>
                                <p>{item.payment}</p>
                              </td>
                              <td>
                                <p>{item.mda}</p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                )}
                {linkedTab === 'file-data' && (
                  <>
                    {linkLoading ? (
                      'Loading...'
                    ) : (
                      <>
                        {linkedData.results.map((item, x) => (
                          <div
                            className='card'
                            key={1 + x}
                            onClick={() =>
                              navigate(`/dashboard/general_data/${item.id}`)
                            }
                          >
                            <p>
                              Category: <b>{item.category}</b>
                            </p>
                            {Object.entries(item.fileData)
                              .slice(0, 3)
                              .map((nuk, i) => (
                                <p key={i + 1}>
                                  {nuk[0]}: <b> {nuk[1]}</b>
                                </p>
                              ))}
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
                {linkedTab === 'corporate' && (
                  <>
                    {linkLoading ? (
                      'Loading...'
                    ) : (
                      <table>
                        <thead>
                          <tr>
                            <th>Business Name</th>
                            <th>Trade Name</th>
                            <th>Enrollee Id</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            onClick={() =>
                              navigate(
                                `/dashboard/corporate_page/${linkedData.id}`
                              )
                            }
                          >
                            <td>{linkedData.businessName}</td>
                            <td>
                              <p>{linkedData.tradeName}</p>
                            </td>
                            <td>
                              <p>{linkedData.enrolleeId}</p>
                            </td>
                            <td>
                              <p>{linkedData.email}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {informationPage === 'social' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'>
                    <div className='icon'>
                      <BsFillMegaphoneFill />
                    </div>
                    <h2>Social Media</h2>
                  </div>
                  {IsUpload && (
                    <div className='two'>
                      <button
                        className='active'
                        onClick={handleAddSocialToggle}
                      >
                        Add
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className='info-table'>
                <table>
                  <thead>
                    <tr>
                      <th>Platform</th>
                      <th>Content</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(taxPayerData).includes('socialPosts') && (
                      <>
                        {taxPayerData.socialPosts.map((item, i) => (
                          <tr key={i + 1}>
                            <td>{item.platform}</td>
                            <td>{item.content}</td>
                            <td>
                              <a
                                href={item.postUrl}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <button>Visit</button>
                              </a>
                              {item.mediaUrls.map((link, i) => (
                                <a
                                  href={link}
                                  target='_blank'
                                  rel='noreferrer'
                                  key={i}
                                >
                                  <button>Media</button>
                                </a>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {informationPage === 'edit' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info_top'>
                  <div className='icon'>
                    <BiEdit />
                  </div>
                  <h2>Edit Information</h2>
                </div>
              </div>
              <div className='info-table'>
                <form onSubmit={handleSubmit(onEnroll)}>
                  <div className='top_form'>
                    <div className='top_six'>
                      <div className='card'>
                        <p>VIP?</p>
                        <select
                          id=''
                          name='isVip'
                          {...register('isVip', {
                            required: 'Vip is required',
                          })}
                        >
                          <option value={`${taxPayerData.isVip}`}>
                            {taxPayerData.isVip ? 'True' : 'False'}
                          </option>
                          {taxPayerData.isVip ? (
                            <option value='false'>False</option>
                          ) : (
                            <option value='true'>True</option>
                          )}
                        </select>
                        {errors.title && (
                          <h6 className='vError'>{errors.title.message}</h6>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='buttons'>
                    {formLoading ? <h3>Loading...</h3> : <button>Edit</button>}
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={SideBar ? 'right' : 'right active'}>
        <div className='close'>
          <TbArrowBarRight onClick={() => setSideBar(!SideBar)} />
        </div>
        <div className='top'>
          <Link to={`/dashboard/tax_payerSummary/${id}`}>
            <button>Generate Summary</button>
          </Link>
        </div>
        <div className='tag'>
          <h1>More Information</h1>
        </div>
        <div className='links'>
          <ul>
            <li
              className={informationPage === 'personal' ? 'active' : ''}
              onClick={() => setInformationPage('personal')}
            >
              Personal Information
            </li>
            {/* <li
              className={informationPage === 'employement' ? 'active' : ''}
              onClick={() => setInformationPage('employement')}
            >
              Employement information
            </li> */}

            {/* <li
              className={informationPage === 'residency' ? 'active' : ''}
              onClick={() => setInformationPage('residency')}
            >
              Residency Status
            </li> */}
            <li
              className={informationPage === 'spouse' ? 'active' : ''}
              onClick={() => setInformationPage('spouse')}
            >
              Spouse and Kids Information
            </li>
            <li
              className={informationPage === 'assets' ? 'active' : ''}
              onClick={() => setInformationPage('assets')}
            >
              Assets
            </li>
            <li
              className={informationPage === 'linked' ? 'active' : ''}
              onClick={() => {
                setInformationPage('linked')
                FetchLinkedData('e-tax', 1)
              }}
            >
              Linked Data
            </li>
            <li
              className={informationPage === 'social' ? 'active' : ''}
              onClick={() => setInformationPage('social')}
            >
              Social Media
            </li>
            {fixVIP && (
              <li
                className={informationPage === 'edit' ? 'active' : ''}
                onClick={() => setInformationPage('edit')}
              >
                VIP
              </li>
            )}
            {IsEdit && (
              <li
                onClick={() =>
                  navigate(`/dashboard/tax_payers_edit/${taxPayerData.id}`)
                }
              >
                Edit
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default UserPage
