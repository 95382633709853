import React from 'react'
import Loader from '../../../../Images/loader.gif'
import './LoaderComp.scss'

function LoaderComp({ direction }) {
  return (
    <div className={`loader-comp ${direction}`}>
      <img src={Loader} alt='' />
    </div>
  )
}

export default LoaderComp
