import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Loader from '../../../../Images/loader.gif'
import { useNavigate } from 'react-router-dom'
import { BsArrowLeft, BsAsterisk } from 'react-icons/bs'
import { TbReceiptTax } from 'react-icons/tb'
import StateData from '../EnrollPayer/StateData'

function CreateCorporate({ notify, loginData }) {
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const [localGovernment, setLocalGovernment] = useState([])

  const {
    register: TaxRegister,
    handleSubmit: TaxSubmit,
    formState: { errors: TaxErrors },
  } = useForm()
  const SelectState = (e) => {
    const foundState = StateData.find((item) => item.state.name === e)
    setLocalGovernment(foundState.state.locals)
  }
  useEffect(() => {
    const foundState = StateData.find(
      (item) => item.state.name === 'Ondo State'
    )
    setLocalGovernment(foundState.state.locals)
  }, [])
  function cleanObject(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key, value]) => {
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            const cleanedNestedObject = cleanObject(value)
            return Object.keys(cleanedNestedObject).length > 0
          }
          return (
            value !== null &&
            value !== '' &&
            !(typeof value === 'object' && !Object.keys(value).length)
          )
        })
        .map(([key, value]) => {
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            return [key, cleanObject(value)]
          }
          return [key, value]
        })
    )
  }
  const onTxEnroll = (formData) => {
    setFormLoading(true)
    const object = {
      tin: formData.tin,
      tradeName: formData.tradeName,
      dateOfIncorporation: formData.dateOfIncorporation,
      commencementDate: formData.commencementDate,
      registrationDate: formData.registrationDate,
      registrationNumber: formData.registrationNumber,
      businessName: formData.businessName,
      organisationType: formData.organisationType,
      lineOfBusiness: formData.lineOfBusiness,
      phone: formData.phone,
      stateOfResidence: formData.stateOfResidence,
      isVip: formData.isVip,
      numberOfEmployees: formData.numberOfEmployees,
      email: formData.email,
      state: formData.stateOfResidence,
      landlord: formData.landlord,
      importer: formData.importer,
      exporter: formData.exporter,
      lga: formData.lga,
      city: formData.city,
      houseNo: formData.houseNo,
      street: formData.street,
      country: 'Nigeria',
      channel: 'API',
    }
    fetch(`${process.env.REACT_APP_API_URL}/employers`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(cleanObject(object)),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Corporate User Created successfully`)
            setTimeout(() => {
              window.location.replace(`/dashboard/corporate`)
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }

  return (
    <div className='create_payment'>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='enroll_main'>
        {formLoading && (
          <div className='formload'>
            <img src={Loader} alt='' />
          </div>
        )}
        <form className='left' onSubmit={TaxSubmit(onTxEnroll)}>
          <div className='top_form'>
            <div className='taxtrans'>
              <div className='left_top diff2'>
                <TbReceiptTax />
                <h2>Add New Corporate User</h2>
              </div>
            </div>
            <div className='top_six'>
              <div className='address'>
                <p>
                  Trade Name{' '}
                  <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                </p>
                <input
                  type='text'
                  name='tradeName'
                  id=''
                  {...TaxRegister('tradeName', {
                    required: ' Required',
                  })}
                />
                {TaxErrors.tradeName && (
                  <h6 className='vError' color='red'>
                    {TaxErrors.tradeName.message}
                  </h6>
                )}
              </div>
              <div className='address'>
                <p>
                  Business Name{' '}
                  <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                </p>
                <input
                  type='text'
                  name='businessName'
                  id=''
                  {...TaxRegister('businessName', {
                    required: 'Required',
                  })}
                />
                {TaxErrors.businessName && (
                  <h6 className='vError'>{TaxErrors.businessName.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>TIN</p>
                <input type='text' name='tin' {...TaxRegister('tin')} />
                {TaxErrors.tin && (
                  <h6 className='vError' color='red'>
                    {TaxErrors.tin.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Enrollee Id</p>
                <input
                  type='text'
                  name='enrolleeId'
                  {...TaxRegister('enrolleeId')}
                />
              </div>
              <div className='card'>
                <p>Date Of Incorporation</p>
                <input
                  type='date'
                  name='dateOfIncorporation'
                  id=''
                  {...TaxRegister('dateOfIncorporation')}
                />
              </div>
              <div className='card'>
                <p>Commencement Date</p>
                <input
                  type='date'
                  name='commencementDate'
                  id=''
                  {...TaxRegister('commencementDate')}
                />
                {TaxErrors.commencementDate && (
                  <h6 className='vError'>
                    {TaxErrors.commencementDate.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Registration Date</p>
                <input
                  type='date'
                  name='registrationDate'
                  id=''
                  {...TaxRegister('registrationDate')}
                />
                {TaxErrors.registrationDate && (
                  <h6 className='vError'>
                    {TaxErrors.registrationDate.message}
                  </h6>
                )}
              </div>

              <div className='card'>
                <p>Registration Number</p>
                <input
                  type='text'
                  name='registrationNumber'
                  {...TaxRegister('registrationNumber')}
                />
                {TaxErrors.registrationNumber && (
                  <h6 className='vError'>
                    {TaxErrors.registrationNumber.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Organisation Type</p>
                <input type='text' name='organisationType' />
              </div>
              <div className='card'>
                <p>Line Of Business</p>
                <input type='text' name='lineOfBusiness' />
              </div>
              <div className='card'>
                <p>
                  Phone <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                </p>
                <input
                  type='tel'
                  name='phone'
                  {...TaxRegister('phone', {
                    required: 'Required',
                  })}
                />
                {TaxErrors.phone && (
                  <h6 className='vError'>{TaxErrors.phone.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Email</p>
                <input type='email' name='email' {...TaxRegister('email')} />
                {TaxErrors.email && (
                  <h6 className='vError'>{TaxErrors.email.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Number Of Employees</p>
                <input
                  type='number'
                  name='numberOfEmployees'
                  {...TaxRegister('numberOfEmployees')}
                />
                {TaxErrors.numberOfEmployees && (
                  <h6 className='vError'>
                    {TaxErrors.numberOfEmployees.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>State Of Residence</p>
                <select
                  name='stateOfResidence'
                  id=''
                  {...TaxRegister('stateOfResidence')}
                  onChange={(e) => {
                    SelectState(e.target.value)
                  }}
                >
                  <option value=''>-select-</option>
                  {StateData.map((item) => (
                    <option value={item.state.name} key={item.state.name}>
                      {item.state.name}
                    </option>
                  ))}
                </select>
                {TaxErrors.stateOfResidence && (
                  <h6 className='vError'>
                    {TaxErrors.stateOfResidence.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>LGA</p>
                <select name='lga' id='' {...TaxRegister('lga')}>
                  <option value=''>Select</option>
                  {localGovernment.map((item) => (
                    <option value={item.name} key={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {TaxErrors.lga && (
                  <h6 className='vError'>{TaxErrors.lga.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>City</p>
                <input type='text' name='city' {...TaxRegister('city')} />
                {TaxErrors.city && (
                  <h6 className='vError'>{TaxErrors.city.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>House No</p>
                <input type='text' name='houseNo' {...TaxRegister('houseNo')} />
                {TaxErrors.houseNo && (
                  <h6 className='vError'>{TaxErrors.houseNo.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Street</p>
                <input type='text' name='street' {...TaxRegister('street')} />
                {TaxErrors.street && (
                  <h6 className='vError'>{TaxErrors.street.message}</h6>
                )}
              </div>

              <div className='card'>
                <p>VIP</p>
                <select name='' id='' {...TaxRegister('isVip')}>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </select>
              </div>
              <div className='card'>
                <p>Landlord</p>
                <select name='' id='' {...TaxRegister('landlord')}>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </select>
              </div>
              <div className='card'>
                <p>Importer</p>
                <select name='' id='' {...TaxRegister('importer')}>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </select>
              </div>
              <div className='card'>
                <p>Exporter</p>
                <select name='' id='' {...TaxRegister('exporter')}>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </select>
              </div>
            </div>
          </div>
          <div className='submit'>
            <button>
              <p>Submit</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateCorporate
