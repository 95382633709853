import React, { useState, useEffect } from 'react'
import './TaxPayer.scss'
import { CiSearch } from 'react-icons/ci'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import API from '../../../../Utility/API/API'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import FileViewer from 'react-file-viewer'
import Pagination from '../../Component/Pagination/Pagination'

function TaxPayer({ IsUpload }) {
  const authToken = sessionStorage.getItem('AdminId')
  const navigate = useNavigate()
  const Token = sessionStorage.getItem('Admin-Id')
  const [loading, setLoading] = useState(true)
  const [taxPayer, setTaxPayer] = useState([])
  const [fileSearch, setFileSearch] = useState('')
  const [showPdf, setShowPdf] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationType, setPaginationType] = useState('default')
  const [selectedEtax, setSelectedEtax] = useState({
    uploadType: '',
  })
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchETax(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    API.FetchETax(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const FindETax = () => {
    setLoading(true)
    setCurrentPage(1)
    fetch(
      `${process.env.REACT_APP_API_URL}/e-tax?searchTerm=${fileSearch}&sortBy=fileName:asc&limit=20&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
          setPaginationType('searchTerm')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const searchPaginationClick = (num) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/e-tax?searchTerm=${fileSearch}&sortBy=fileName:asc&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }

  const FetchDetails = (id) => {
    const foundEtax = taxPayer.results.find((item) => item.id === id)
    setSelectedEtax(foundEtax)
    if (
      taxPayer.results.find((item) => item.id === id).uploadType === 'e-tax'
    ) {
      setShowPdf(true)
    }
  }

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='taxPayer'>
      {showPdf && (
        <div className='showPDF'>
          <div className='cover'>
            <div className='download'>
              <a
                href={`${process.env.REACT_APP_API_URL}/media/${selectedEtax.path}`}
              >
                <button>Download PDF</button>
              </a>
              <AiOutlineCloseCircle onClick={() => setShowPdf(false)} />
            </div>
            <div className='file-cover'>
              <FileViewer
                fileType='pdf'
                filePath={`${process.env.REACT_APP_API_URL}/media/${selectedEtax.path}`}
              />
            </div>
          </div>
        </div>
      )}
      <div className='search_top'>
        <div className='form'>
          <input
            type='text'
            name=''
            id=''
            placeholder='Peter O'
            onChange={(e) => setFileSearch(e.target.value)}
            defaultValue={fileSearch}
          />
          <button onClick={() => FindETax()}>
            <CiSearch />
          </button>
        </div>
      </div>
      <div className='top'>
        <h1>PDF Records</h1>
        <div className='add'>
          {IsUpload && (
            <button onClick={() => navigate('/dashboard/upload_etax')}>
              Upload E-Tax
            </button>
          )}
        </div>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>File Name</th>
              <th>File Link</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {taxPayer.results.map((item, i) => (
              <tr key={item.id} className={item.isVip ? 'blur' : ''}>
                <td>{item.fileName}</td>
                <td>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/media/${item.path}`}
                  >
                    <p>{item.fullPath}</p>
                  </a>
                </td>

                <td className='actions'>
                  <BiDotsHorizontalRounded
                    onClick={() => FetchDetails(item.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {paginationType !== 'default' ? (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxPayer.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            searchPaginationClick(page)
          }}
        />
      ) : (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxPayer.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            paginationClick(page)
          }}
        />
      )}
    </div>
  )
}

export default TaxPayer
