import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './BulkResponse.scss'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { AiOutlineDeliveredProcedure } from 'react-icons/ai'
import { BsFillExclamationCircleFill, BsCheckAll } from 'react-icons/bs'
import { MdCheckBoxOutlineBlank } from 'react-icons/md'
import Loader from '../../../../Images/loader.gif'
import * as XLSX from 'xlsx'
import Pagination from '../../Component/Pagination/Pagination'
import API from '../../../../Utility/API/API'
import LoaderComp from '../../Component/Loader/LoaderComp'

function BulkResponse() {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [selecetedBulk, setSelecetedBulk] = useState({})
  const [errors, setErrors] = useState({})
  const [errorsLong, setErrorsLong] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationLoading, setPaginationLoading] = useState(false)
  const [downloadingLoading, setDownloadingLoading] = useState(false)

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/bulk-uploads/${id}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedBulk(data)
          API.FetchBulkError(id, 20, 1).then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                setErrors(data)
                setLoading(false)
              })
            } else if (res.status === 401) {
              RefreshToken()
            } else {
              res.json().then((data) => {})
            }
          })
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })

    // eslint-disable-next-line
  }, [])
  const paginationClick = (page) => {
    setPaginationLoading(true)
    API.FetchBulkError(id, 20, page).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setErrors(data)
          setPaginationLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }

  const arrayToExcel = () => {
    let data = null
    setDownloadingLoading(true)
    API.FetchBulkError(id, 500, 1).then((res) => {
      if (res.status === 200) {
        res.json().then((info) => {
          if (Object.keys(info.results[0]).includes('extraData')) {
            data = info.results.map((item) => item.extraData)
          } else {
            data = info.results.map((item) => item.rawData)
          }
          // Ensure there's data in the array
          if (data.length === 0) {
            return
          }

          // Get the keys from the first object as column names
          const columns = Object.keys(data[0])

          // Create an array with the data, including column names as the first row
          const dataArray = [
            columns,
            ...data.map((item) => columns.map((col) => item[col])),
          ]

          // Create a worksheet
          const ws = XLSX.utils.aoa_to_sheet(dataArray)

          // Create a workbook
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

          // Save the workbook as an Excel file
          XLSX.writeFile(wb, `${selecetedBulk.uploadType}-failed-data.xlsx`)
          setDownloadingLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='bulkResponse'>
      <div className='stats'>
        <div className='card'>
          <div className='icon'>
            <BsFillExclamationCircleFill />
          </div>
          <div className='fig'>
            <h1>{selecetedBulk.results.failed}</h1>
            <div className='tag'>failed</div>
          </div>
        </div>
        <div className='card'>
          <div className='icon'>
            <BsCheckAll />
          </div>
          <div className='fig'>
            <h1>{selecetedBulk.results.passed}</h1>
            <div className='tag'>passed</div>
          </div>
        </div>
        <div className='card'>
          <div className='icon'>
            <AiOutlineDeliveredProcedure />
          </div>
          <div className='fig'>
            <h1>{selecetedBulk.results.processed}</h1>
            <div className='tag'>processed</div>
          </div>
        </div>
        <div className='card'>
          <div className='icon'>
            <HiOutlineUserGroup />
          </div>
          <div className='fig'>
            <h1>{selecetedBulk.results.total}</h1>
            <div className='tag'>total</div>
          </div>
        </div>
      </div>
      <div className='errors'>
        <h1>
          Upload Type : <b>{selecetedBulk.uploadType}</b>
        </h1>
        <h1>
          Status: <b>{selecetedBulk.processingStatus}</b>
        </h1>
        {selecetedBulk.processingStatus === 'failed' && (
          <>
            <h2>
              Errors : <b>{selecetedBulk.failedReason}</b>
            </h2>
            {downloadingLoading ? (
              <span>Loading...</span>
            ) : (
              <button onClick={() => arrayToExcel()}>Export to Excel</button>
            )}
          </>
        )}
      </div>
      {paginationLoading ? (
        <LoaderComp />
      ) : (
        <>
          <div className='table'>
            <table>
              <thead>
                <tr>
                  <th>Row No.</th>
                  <th>Reason</th>
                  {selecetedBulk.uploadType !== 'file-data' &&
                    selecetedBulk.uploadType !== 'tax-transaction' && (
                      <th>Old Data</th>
                    )}
                  <th>New Data</th>
                </tr>
              </thead>
              <tbody>
                {errors.results.map((item, i) => (
                  <tr key={i + 1}>
                    <td>{item.rowNumber}</td>
                    <td>{item.reason}</td>
                    {selecetedBulk.uploadType === 'tax-payer' && (
                      <>
                        {Object.keys(item).includes('rawData') ? (
                          <td className='old'>
                            <p>
                              <b>First Name</b>: {item.rawData.firstName}
                            </p>
                            <p>
                              <b>Last Name</b>: {item.rawData.lastName}
                            </p>
                            <p>
                              <b>NIN</b>: {item.rawData.nin}
                            </p>
                            <p>
                              <b>ODTIN</b>: {item.rawData.odTin}
                            </p>
                            <p>
                              <b>Phone</b>: {item.rawData.phone}
                            </p>
                            <p>
                              <b>Email</b>: {item.rawData.email}
                            </p>
                            <p>
                              <b>TIN</b>: {item.rawData.tin}
                            </p>
                          </td>
                        ) : (
                          <td className='old'>
                            <p>
                              <b>First Name</b>:
                            </p>
                            <p>
                              <b>Last Name</b>:
                            </p>
                            <p>
                              <b>NIN</b>:
                            </p>
                            <p>
                              <b>ODTIN</b>:
                            </p>
                            <p>
                              <b>Phone</b>:
                            </p>
                            <p>
                              <b>Email</b>:
                            </p>
                            <p>
                              <b>TIN</b>:
                            </p>
                          </td>
                        )}
                        {Object.keys(item).includes('extraData') ? (
                          <td className='old'>
                            <p>
                              <b>First Name</b>: {item.extraData.firstName}
                            </p>
                            <p>
                              <b>First Name</b>: {item.extraData.lastName}
                            </p>
                            <p>
                              <b>NIN</b>: {item.extraData.nin}
                            </p>
                            <p>
                              <b>ODTIN</b>: {item.extraData.odTin}
                            </p>
                            <p>
                              <b>Phone</b>: {item.extraData.phone}
                            </p>
                            <p>
                              <b>Email</b>: {item.extraData.email}
                            </p>
                            <p>
                              <b>TIN</b>: {item.extraData.tin}
                            </p>
                          </td>
                        ) : (
                          <td className='old'>
                            <p>
                              <b>First Name</b>:
                            </p>
                            <p>
                              <b>Last Name</b>:
                            </p>
                            <p>
                              <b>NIN</b>:
                            </p>
                            <p>
                              <b>ODTIN</b>:
                            </p>
                            <p>
                              <b>Phone</b>:
                            </p>
                            <p>
                              <b>Email</b>:
                            </p>
                            <p>
                              <b>TIN</b>:
                            </p>
                          </td>
                        )}
                      </>
                    )}
                    {selecetedBulk.uploadType === 'vehicle' && (
                      <>
                        {Object.keys(item).includes('rawData') ? (
                          <td className='old'>
                            <p>
                              <b>Phone</b>: {item.rawData.phone}
                            </p>
                            <p>
                              <b>Application Id</b>:{' '}
                              {item.rawData.applicationId}
                            </p>
                            <p>
                              <b>Plate Number</b>: {item.rawData.plateNumber}
                            </p>
                            <p>
                              <b>Chasis Number</b>: {item.rawData.chasisNumber}
                            </p>
                          </td>
                        ) : (
                          <td className='old'>
                            <p>
                              <b>Phone</b>:
                            </p>
                            <p>
                              <b>Application Id</b>:
                            </p>
                            <p>
                              <b>Plate Number</b>:
                            </p>
                            <p>
                              <b>Chasis Number</b>:
                            </p>
                          </td>
                        )}
                        {Object.keys(item).includes('extraData') ? (
                          <td className='old'>
                            <p>
                              <b>Phone</b>: {item.extraData.phone}
                            </p>
                            <p>
                              <b>Application Id</b>:{' '}
                              {item.extraData.applicationId}
                            </p>
                            <p>
                              <b>Plate Number</b>: {item.extraData.plateNumber}
                            </p>
                            <p>
                              <b>Chasis Number</b>:{' '}
                              {item.extraData.chasisNumber}
                            </p>
                          </td>
                        ) : (
                          <td className='old'>
                            <p>
                              <b>Phone</b>:
                            </p>
                            <p>
                              <b>Application Id</b>:
                            </p>
                            <p>
                              <b>Plate Number</b>:
                            </p>
                            <p>
                              <b>Chasis Number</b>:
                            </p>
                          </td>
                        )}
                      </>
                    )}
                    {selecetedBulk.uploadType === 'file-data' && (
                      <>
                        {Object.keys(item).includes('extraData') && (
                          <td className='old'>
                            <p>
                              <b>Category</b>:{' '}
                              {item.extraData.category
                                ? item.extraData.category
                                : ''}
                            </p>
                            {item.extraData.fileData ? (
                              <>
                                {Object.entries(item.extraData.fileData)
                                  .slice(0, 4)
                                  .map((nuk, i) => (
                                    <p key={i + 1}>
                                      <b> {nuk[0]}</b> : {nuk[1]}
                                    </p>
                                  ))}
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                        )}
                      </>
                    )}
                    {selecetedBulk.uploadType === 'tax-transaction' && (
                      <>
                        {Object.keys(item).includes('extraData') ? (
                          <td className='old'>
                            <p>
                              <b>Payer Name</b>: {item.extraData.payerName}
                            </p>
                            <p>
                              <b>amount</b>: {item.extraData.amount}
                            </p>
                            <p>
                              <b>payment</b>: {item.extraData.payment}
                            </p>
                            <p>
                              <b>Receipt No</b>: {item.extraData.receiptNo}
                            </p>
                          </td>
                        ) : (
                          <td className='old'>
                            <p>
                              <b>payerName</b>:
                            </p>
                            <p>
                              <b>amount</b>:
                            </p>
                            <p>
                              <b>payment</b>:
                            </p>
                            <p>
                              <b>receiptNo</b>:
                            </p>
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            className='pagination-bar'
            currentPage={currentPage}
            totalCount={errors.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage(page)
              paginationClick(page)
            }}
          />
        </>
      )}
    </div>
  )
}

export default BulkResponse
