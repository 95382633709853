import React, { useState, useEffect } from 'react'
import './Sidebar.scss'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import {
  RiHome5Line,
  RiFile3Line,
  RiLogoutCircleRLine,
  RiArrowRightSLine,
  RiArrowDownSLine,
} from 'react-icons/ri'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { VscInbox } from 'react-icons/vsc'
import { TbSettings, TbReceiptTax, TbFileDescription } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { GiTwoCoins } from 'react-icons/gi'
import { FaFilePdf, FaAngleRight, FaAngleDown } from 'react-icons/fa'
import { MdSecurity } from 'react-icons/md'

function Sidebar({ page, logOut, loginData }) {
  const { toggleSidebar, handleSidebarToggle } = useGlobalContext()
  const navigate = useNavigate()
  const [showEnrollment, setShowEnrollment] = useState('false')
  const [showTaxPayments, setShowTaxPayments] = useState(false)
  useEffect(() => {
    if (
      page === 'tax_payer' ||
      page === 'inactive_payer' ||
      page === 'enroll_payer' ||
      page === 'user_transaction' ||
      page === 'tax_transactions' ||
      page === 'tax_transaction' ||
      page === 'user_page'
    ) {
      setShowEnrollment(!showEnrollment)
    } else {
      setShowEnrollment(showEnrollment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const checkEnrollment = () => {
    if (showEnrollment !== 'false') {
      setShowEnrollment('false')
    }
  }

  useEffect(() => {
    if (page.includes('receipt')) {
      setShowTaxPayments(true)
    }
  }, [showTaxPayments])

  return (
    <div className={toggleSidebar ? 'sidebar' : 'sidebar active'}>
      <div className='columns'>
        <>
          <div
            className={
              page === 'home' ||
              page === 'analytics' ||
              page === 'search-result'
                ? 'row active'
                : 'row'
            }
            onClick={() => {
              navigate('/dashboard/home')
              checkEnrollment()
              handleSidebarToggle()
            }}
          >
            <div className='icon'>
              <RiHome5Line />
            </div>
            <p>Dashboard</p>
          </div>
          <>
            <div className='enroll'>
              <div
                className={
                  page === 'tax_payer' ||
                  page.includes('tax_payers') ||
                  page === 'enroll_payer' ||
                  page === 'user_transaction' ||
                  page === 'tax_transactions' ||
                  page === 'user_page'
                    ? 'row active'
                    : 'row'
                }
                onClick={() => {
                  navigate('/dashboard/tax_payer')
                  handleSidebarToggle()
                }}
              >
                <div className='icon'>
                  <GiTwoCoins />
                </div>
                <p>Tax Payer</p>
              </div>
            </div>
            <div
              className={
                page === 'corporate' ||
                page === 'corporate_page' ||
                page === 'corporate_single' ||
                page === 'enroll_corporate'
                  ? 'row active'
                  : 'row'
              }
              onClick={() => {
                navigate('/dashboard/corporate')
                checkEnrollment()
                handleSidebarToggle()
              }}
            >
              <div className='icon'>
                <VscInbox />
              </div>
              <p>Corporate</p>
            </div>
            <div
              className={
                page === 'e_tax' || page.includes('upload_etax')
                  ? 'row active'
                  : 'row'
              }
              onClick={() => {
                navigate('/dashboard/e_tax')
                checkEnrollment()
                handleSidebarToggle()
              }}
            >
              <div className='icon'>
                <FaFilePdf />
              </div>
              <p>PDF Records</p>
            </div>
            <div
              className={page === 'tax_transaction' ? 'row active' : 'row'}
              onClick={() => {
                navigate('/dashboard/tax_transaction')
                handleSidebarToggle()
              }}
            >
              <div className='icon'>
                <TbSettings />
              </div>
              <p>Tax Transactions</p>
            </div>

            {/* <div
                  className={
                    page === 'transactions_category' ? 'row active' : 'row'
                  }
                  onClick={() => {
                    navigate('/dashboard/transactions_category')
                    checkEnrollment()
                    handleSidebarToggle()
                  }}
                >
                  <div className='icon'>
                    <MdOutlineReceiptLong />
                  </div>
                  <p>Transaction by Category</p>
                </div> */}
            {/* <div
                  className={
                    page.includes('file-data') || page.includes('general_data')
                      ? 'row active'
                      : 'row'
                  }
                  onClick={() => {
                    navigate('/dashboard/file-data')
                    checkEnrollment()
                    handleSidebarToggle()
                  }}
                >
                  <div className='icon'>
                    <TbFileDescription />
                  </div>
                  <p>File Data</p>
                </div> */}

            {/* <div className='drop'>
              <div
                className='row'
                onClick={() => setShowTaxPayments(!showTaxPayments)}
              >
                <div className='icon'>
                  <TbReceiptTax />
                </div>
                <p>Tax Payments</p>
                <div className='icon'>
                  {showTaxPayments ? <FaAngleDown /> : <FaAngleRight />}
                </div>
              </div>
              {showTaxPayments && (
                <div className='diffs'>
                  <div
                    className={page.includes('receipt') ? 'row active' : 'row'}
                    onClick={() => {
                      navigate('/dashboard/receipts')
                      checkEnrollment()
                      handleSidebarToggle()
                    }}
                  >
                    <div className='icon'>
                      <TbReceiptTax />
                    </div>
                    <p>Receipts</p>
                  </div>
                  <div
                    className={
                      page.includes('presumptive') ? 'row active' : 'row'
                    }
                    onClick={() => {
                      navigate('/dashboard/presumptive-tax')
                      checkEnrollment()
                      handleSidebarToggle()
                    }}
                  >
                    <div className='icon'>
                      <TbReceiptTax />
                    </div>
                    <p>Presumptive Tax</p>
                  </div>
                </div>
              )}
            </div> */}
          </>
          {loginData.role === 'security admin' || loginData.role === 'admin' ? (
            <div
              className={
                page === 'upload_data' ||
                page.includes('admin_user') ||
                page.includes('bulk_response')
                  ? 'row active'
                  : 'row'
              }
              onClick={() => {
                navigate('/dashboard/admin_user')
                checkEnrollment()
                handleSidebarToggle()
              }}
            >
              <div className='icon'>
                <HiOutlineUserCircle />
              </div>
              <p>Admin User</p>
            </div>
          ) : (
            ''
          )}
        </>

        {loginData.role === 'security admin' && (
          <div
            className={page === 'audit-trail' ? 'row active' : 'row'}
            onClick={() => {
              navigate('/dashboard/audit-trail')
              checkEnrollment()
              handleSidebarToggle()
            }}
          >
            <div className='icon'>
              <MdSecurity />
            </div>
            <p>Audit Trail</p>
          </div>
        )}

        <div
          className='row'
          onClick={() => {
            logOut()
            navigate('/')
          }}
        >
          <div className='icon'>
            <RiLogoutCircleRLine />
          </div>
          <p>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
