import React, { useState } from 'react'
import ReceiptData from './ReceiptData'
import { useNavigate } from 'react-router-dom'
import { AiOutlineCloseCircle } from 'react-icons/ai'

function PresumptiveTax({ notify }) {
  const [taxInput, setTaxInput] = useState('')
  const [foundTax, setFoundTax] = useState(null)
  const [showOdtin, setShowOdtin] = useState(false)
  const [validOdtin, setValidOdtin] = useState('')
  const navigate = useNavigate()
  function verifyReceipt(params) {
    const foundTax = ReceiptData.filter((item) => item.receipt_no === taxInput)
    if (foundTax.length > 0) {
      setFoundTax(foundTax)
    } else {
      notify('error', 'Receipt not found.')
      setFoundTax([])
    }
  }
  function verifyOdtin() {
    setValidOdtin('false')
  }
  return (
    <div className='taxtransaction'>
      {showOdtin && (
        <div className='ODTINmodal'>
          <div className='tin_modal'>
            <div className='top'>
              <div className='close'>
                <AiOutlineCloseCircle
                  onClick={() => {
                    setShowOdtin(false)
                    setValidOdtin('')
                  }}
                />
              </div>
            </div>
            <h1>Verify ODTIN to continue your application</h1>
            <div className='flex-input'>
              <input type='text' placeholder='Enter ODTIN' />
              <button onClick={verifyOdtin}>Verify</button>
            </div>
            {validOdtin === 'false' && (
              <div className='unavailable'>
                <p>ODTIN not available</p>
                <button>Update</button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className='flexs'>
        <div className='date-filter dif'>
          <h2>Enter Receipt Number</h2>
          <div className='date-filter-cover'>
            <input
              type='text'
              className='dif'
              placeholder='receipt  number'
              onChange={(e) => setTaxInput(e.target.value)}
            />
            <div className='submit'>
              <button className='dif' onClick={() => verifyReceipt()}>
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
      {foundTax && (
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th>Taxpayer Name</th>
                <th>ODTIN</th>
                <th>Amount</th>
                <th>Receipt Number</th>
                <th>Payment Date</th>
                <th>Print</th>
              </tr>
            </thead>
            <tbody>
              {foundTax.map((item, i) => (
                <tr key={i + 1}>
                  <td>{item.received_from}</td>
                  <td>-N/A-</td>
                  <td>N{item.amount}</td>
                  <td>{item.receipt_no}</td>
                  <td>{item.payment_date}</td>
                  <td>
                    <button onClick={() => setShowOdtin(true)}>
                      Generate Certificate
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default PresumptiveTax
