const ReceiptData = [
  {
    file_number: '2071939',
    received_from: 'ALONGE AYOMIDE EMMANUEL',
    address: 'abuja',
    amount: '5,000.00',
    the_sum_of: 'FIVE THOUSAND NIGERIAN NAIRA ONLY',
    being: 'FEES - GENERAL-STATE OF ORIGIN CERTIFICATE',
    signature_or_mark_of_payer: 'ALONGE',
    teller_no: '0508327',
    bank: 'WEMA Bank Plc',
    receipt_no: '1418442',
    printed_date: '29-05-2024',
    period_ending: '11102100200_8662',
    head_no: '11102100200',
    payment_date: '14-06-2023',
    sub_head_no: '12020494',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498191',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071940',
    received_from: 'JOHN DOE',
    address: 'lagos',
    amount: '7,000.00',
    the_sum_of: 'SEVEN THOUSAND NIGERIAN NAIRA ONLY',
    being: 'FEES - BIRTH CERTIFICATE',
    signature_or_mark_of_payer: 'DOE',
    teller_no: '0508328',
    bank: 'GTBank Plc',
    receipt_no: '1418443',
    printed_date: '30-05-2024',
    period_ending: '11102100201_8663',
    head_no: '11102100201',
    payment_date: '15-06-2023',
    sub_head_no: '12020495',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498192',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071941',
    received_from: 'JANE SMITH',
    address: 'ikeja',
    amount: '8,500.00',
    the_sum_of: 'EIGHT THOUSAND FIVE HUNDRED NIGERIAN NAIRA ONLY',
    being: 'FEES - MARRIAGE CERTIFICATE',
    signature_or_mark_of_payer: 'SMITH',
    teller_no: '0508329',
    bank: 'Access Bank Plc',
    receipt_no: '1418444',
    printed_date: '31-05-2024',
    period_ending: '11102100202_8664',
    head_no: '11102100202',
    payment_date: '16-06-2023',
    sub_head_no: '12020496',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498193',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071942',
    received_from: 'MICHAEL JOHNSON',
    address: 'surulere',
    amount: '3,500.00',
    the_sum_of: 'THREE THOUSAND FIVE HUNDRED NIGERIAN NAIRA ONLY',
    being: 'FEES - LAND CERTIFICATE',
    signature_or_mark_of_payer: 'JOHNSON',
    teller_no: '0508330',
    bank: 'UBA Plc',
    receipt_no: '1418445',
    printed_date: '01-06-2024',
    period_ending: '11102100203_8665',
    head_no: '11102100203',
    payment_date: '17-06-2023',
    sub_head_no: '12020497',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498194',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071943',
    received_from: 'OLUWATOBI ADELEKE',
    address: 'mushin',
    amount: '6,000.00',
    the_sum_of: 'SIX THOUSAND NIGERIAN NAIRA ONLY',
    being: 'FEES - DEATH CERTIFICATE',
    signature_or_mark_of_payer: 'ADELEKE',
    teller_no: '0508331',
    bank: 'First Bank Plc',
    receipt_no: '1418446',
    printed_date: '02-06-2024',
    period_ending: '11102100204_8666',
    head_no: '11102100204',
    payment_date: '18-06-2023',
    sub_head_no: '12020498',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498195',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071944',
    received_from: 'EMMANUEL EZE',
    address: 'ajah',
    amount: '4,500.00',
    the_sum_of: 'FOUR THOUSAND FIVE HUNDRED NIGERIAN NAIRA ONLY',
    being: 'FEES - BUSINESS REGISTRATION',
    signature_or_mark_of_payer: 'EZE',
    teller_no: '0508332',
    bank: 'Zenith Bank Plc',
    receipt_no: '1418447',
    printed_date: '03-06-2024',
    period_ending: '11102100205_8667',
    head_no: '11102100205',
    payment_date: '19-06-2023',
    sub_head_no: '12020499',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498191',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071945',
    received_from: 'MARIAM BELLO',
    address: 'lekki',
    amount: '5,200.00',
    the_sum_of: 'FIVE THOUSAND TWO HUNDRED NIGERIAN NAIRA ONLY',
    being: 'FEES - VEHICLE REGISTRATION',
    signature_or_mark_of_payer: 'BELLO',
    teller_no: '0508333',
    bank: 'EcoBank Plc',
    receipt_no: '1418448',
    printed_date: '04-06-2024',
    period_ending: '11102100206_8668',
    head_no: '11102100206',
    payment_date: '20-06-2023',
    sub_head_no: '12020500',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498196',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071946',
    received_from: 'CHINEDU NNAMDI',
    address: 'oshodi',
    amount: '7,500.00',
    the_sum_of: 'SEVEN THOUSAND FIVE HUNDRED NIGERIAN NAIRA ONLY',
    being: 'FEES - IMPORT CERTIFICATE',
    signature_or_mark_of_payer: 'NNAMDI',
    teller_no: '0508334',
    bank: 'Fidelity Bank Plc',
    receipt_no: '1418449',
    printed_date: '05-06-2024',
    period_ending: '11102100207_8669',
    head_no: '11102100207',
    payment_date: '21-06-2023',
    sub_head_no: '12020501',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498197',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071947',
    received_from: 'ADEBAYO ABIODUN',
    address: 'badagry',
    amount: '6,800.00',
    the_sum_of: 'SIX THOUSAND EIGHT HUNDRED NIGERIAN NAIRA ONLY',
    being: 'FEES - EXPORT CERTIFICATE',
    signature_or_mark_of_payer: 'ABIODUN',
    teller_no: '0508335',
    bank: 'Polaris Bank Plc',
    receipt_no: '1418450',
    printed_date: '06-06-2024',
    period_ending: '11102100208_8670',
    head_no: '11102100208',
    payment_date: '22-06-2023',
    sub_head_no: '12020502',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498198',
    printed_by: '',
    printed: false,
  },
  {
    file_number: '2071948',
    received_from: 'KEHINDE OLA',
    address: 'epe',
    amount: '9,000.00',
    the_sum_of: 'NINE THOUSAND NIGERIAN NAIRA ONLY',
    being: 'FEES - EDUCATION CERTIFICATE',
    signature_or_mark_of_payer: 'OLA',
    teller_no: '0508336',
    bank: 'Sterling Bank Plc',
    receipt_no: '1418451',
    printed_date: '07-06-2024',
    period_ending: '11102100209_8671',
    head_no: '11102100209',
    payment_date: '23-06-2023',
    sub_head_no: '12020503',
    signature_or_mark_of_collector:
      'https://res.cloudinary.com/dsmzwfsvi/image/upload/v1718200869/prod/i9ujumcu1bzzdpdeuzkg.png',
    phone: '09074498199',
    printed_by: '',
    printed: false,
  },
]

export default ReceiptData
