import React, { useState, useEffect } from 'react'
import { BsArrowLeft, BsBuilding } from 'react-icons/bs'
import { AiOutlineUser } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { MdOutlineHouse, MdOutlineFamilyRestroom } from 'react-icons/md'
import { TbArrowBarLeft, TbArrowBarRight, TbReceiptTax } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import API from '../../../../Utility/API/API'
import { useForm } from 'react-hook-form'

function Edit({ notify }) {
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const { id } = useParams()
  const [SideBar, setSideBar] = useState('false')
  const [informationPage, setInformationPage] = useState('personal')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(false)
  const [taxPayerData, setTaxPayerData] = useState({
    residency: {
      address: '',
      city: '',
      country: '',
      lga: '',
      nearestBusStop: '',
      numberOfNeighbours: 0,
      residentType: '',
      state: '',
      yearsOfStay: 0,
    },
    spouse: {
      firstName: '',
      lastName: '',
      tin: '',
      dateOfBirth: '',
      employerName: '',
    },
    children: [],
  })
  const [childInfo, setChildInfo] = useState([{ firstName: '', lastName: '' }])
  const [assestsInfo, setAssestsInfo] = useState([
    {
      vehicleId: '',
      engineNo: '',
      chasisNo: '',
      category: '',
      make: '',
      model: '',
      type: '',
      color: '',
      purpose: '',
      capacity: '',
      plateNumber: '',
      ownerName: '',
      address: '',
      regDate: '',
      regDescription: '',
      year: '',
      officer: '',
    },
  ])
  const handleChildFormChange = (event, index) => {
    let data = [...childInfo]
    data[index][event.target.name] = event.target.value
    setChildInfo(data)
  }
  const handleAssestFormChange = (event, index) => {
    let data = [...assestsInfo]
    data[index][event.target.name] = event.target.value
    setAssestsInfo(data)
  }
  const addChildFields = () => {
    let object = {
      firstName: '',
      lastName: '',
    }
    setChildInfo([...childInfo, object])
  }
  const removeChildFields = (index) => {
    let data = [...childInfo]
    data.splice(index, 1)
    setChildInfo(data)
  }
  const addAssestsFields = () => {
    let object = {
      vehicleId: '',
      engineNo: '',
      chasisNo: '',
      category: '',
      make: '',
      model: '',
      type: '',
      color: '',
      purpose: '',
      capacity: '',
      plateNumber: '',
      ownerName: '',
      address: '',
      regDate: '',
      regDescription: '',
      year: '',
      officer: '',
    }
    setAssestsInfo([...assestsInfo, object])
  }
  const removeAssestsFields = (index) => {
    let data = [...assestsInfo]
    data.splice(index, 1)
    setAssestsInfo(data)
  }
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          sessionStorage.setItem('AdminId', data.tokens.access.token)
          sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
      } else {
        res.json().then((data) => {
          sessionStorage.removeItem('AdminId')
          sessionStorage.removeItem('Admin-Id')
          navigate('/')
        })
      }
    })
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const {
    register: personalRegister,
    handleSubmit: personalHandleSubmit,
    formState: { errors: personalError },
  } = useForm()
  const {
    register: residencyRegister,
    handleSubmit: residencyHandleSubmit,
    formState: { errors: residencyError },
  } = useForm()
  const { register: SpouceRegister, handleSubmit: SpouceHandleSubmit } =
    useForm()
  const onEnroll = (formData) => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/tax-payers/${taxPayerData.id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        isVip: formData.isVip,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax payer Edited successful`)

            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)

            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  function createSubmitObject(formData) {
    const object = {
      title: formData.title,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      email: formData.email?.toLowerCase(),
      phone: formData.phone,
      nin: formData.nin,
      tin: formData.tin,
      odTin: formData.odTin,
      dateOfBirth: formData.dateOfBirth,
      maritalStatus: formData.maritalStatus,
      identificationDocument: {
        type: formData.id_type,
        documentNumber: formData.id_number,
        expiryDate: formData.id_date,
      },
      occupation: formData.occupation,
    }

    // Remove null or empty string values
    const cleanObject = Object.fromEntries(
      Object.entries(object).filter(([key, value]) => {
        if (value && typeof value === 'object') {
          return Object.values(value).some((val) => val !== null && val !== '')
        }
        return value !== null && value !== ''
      })
    )

    // Check nested object separately
    if (cleanObject.identificationDocument) {
      cleanObject.identificationDocument = Object.fromEntries(
        Object.entries(cleanObject.identificationDocument).filter(
          ([key, value]) => value !== null && value !== ''
        )
      )
    }

    return cleanObject
  }

  const onPersonalSubmit = (formData) => {
    setFormLoading(true)
    const object = {
      title: formData.title,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      email: formData.email.toLowerCase(),
      phone: formData.phone,
      nin: formData.nin,
      tin: formData.tin,
      odTin: formData.odTin,
      dateOfBirth: formData.dateOfBirth,
      maritalStatus: formData.maritalStatus,
      identificationDocument: {
        type: formData.id_type,
        documentNumber: formData.id_number,
        expiryDate: formData.id_date,
      },
      occupation: formData.occupation,
    }
    fetch(`${process.env.REACT_APP_API_URL}/tax-payers/${taxPayerData.id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(createSubmitObject(object)),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax payer Edited successful`)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  function createResidencyObject(formData) {
    const object = {
      residency: {
        address: formData.address,
        nearestBusStop: formData.nearestBusStop,
        lga: formData.lga,
        city: formData.city,
        state: formData.state,
        country: 'Nigeria',
        residentType: formData.residentType,
        yearsOfStay: formData.yearsOfStay,
        numberOfNeighbours: formData.numberOfNeighbours,
      },
    }

    // Remove null or empty string values from the nested object
    object.residency = Object.fromEntries(
      Object.entries(object.residency).filter(
        ([key, value]) => value !== null && value !== ''
      )
    )

    return object
  }
  const onResidencySubmit = (formData) => {
    setFormLoading(true)
    const object = {
      residency: {
        address: formData.address,
        nearestBusStop: formData.nearestBusStop,
        lga: formData.lga,
        city: formData.city,
        state: formData.state,
        country: 'Nigeria',
        residentType: formData.residentType,
        yearsOfStay: formData.yearsOfStay,
        numberOfNeighbours: formData.numberOfNeighbours,
      },
    }

    fetch(`${process.env.REACT_APP_API_URL}/tax-payers/${taxPayerData.id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(createResidencyObject(object)),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax payer Edited successful`)

            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)

            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  const onSpouseSubmit = (formData) => {
    setFormLoading(true)
    const object = {
      spouse: {
        firstName: formData.spouseName1,
        lastName: formData.spouseName2,
        tin: formData.spouseTin,
        dateOfBirth: formData.spouseDOB,
        employerName: formData.spouseBiz,
      },
      children: childInfo,
    }
    fetch(`${process.env.REACT_APP_API_URL}/tax-payers/${taxPayerData.id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(object),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax payer Edited successful`)

            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)

            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  const onVehcileSubmit = (formData) => {
    setFormLoading(true)
    const object = {
      assets: assestsInfo,
    }
    fetch(`${process.env.REACT_APP_API_URL}/tax-payers/${taxPayerData.id}`, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(object),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax payer Edited successful`)

            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)

            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }

  useEffect(() => {
    API.FetchTaxPayer(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayerData(data)
          if (Object.keys(data).includes('children')) {
            setChildInfo(data.children)
          }
          if (Object.keys(data).includes('assets')) {
            setAssestsInfo(data.assets)
          }
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      }
    })
    // eslint-disable-next-line
  }, [])

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='userPage'>
      {formLoading && (
        <div className='formload'>
          <img src={Loader} alt='' />
        </div>
      )}
      <div className='left'>
        <div className='top'>
          <div></div>
          <div className='burger'>
            <TbArrowBarLeft onClick={() => setSideBar(!SideBar)} />
          </div>
        </div>
        <div className='back'>
          <div className='back_left'>
            <BsArrowLeft onClick={() => navigate(-1)} />
            <h1>Edit Tax Payer</h1>
          </div>
        </div>
        <div className='information_page editd'>
          {informationPage === 'personal' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <AiOutlineUser />
                </div>
                <h2>Personal Information</h2>
              </div>
              <div className='info_wrap'>
                <form
                  onSubmit={personalHandleSubmit(onPersonalSubmit)}
                  className='top_form'
                >
                  <div className='top_six'>
                    <div className='card'>
                      <p>FIRST NAME</p>
                      <input
                        type='text'
                        name='firstName'
                        id=''
                        {...personalRegister('firstName')}
                        defaultValue={taxPayerData?.firstName}
                      />
                      {personalError.firstName && (
                        <h6 className='vError'>
                          {personalError.firstName.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>MIDDLE NAME</p>
                      <input
                        type='text'
                        name='middleName'
                        {...personalRegister('middleName')}
                        defaultValue={taxPayerData?.middleName}
                      />
                      {personalError.middleName && (
                        <h6 className='vError'>
                          {personalError.middleName.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>LAST NAME</p>
                      <input
                        type='text'
                        name='lastName'
                        {...personalRegister('lastName')}
                        defaultValue={taxPayerData?.lastName}
                      />
                      {personalError.lastName && (
                        <h6 className='vError'>
                          {personalError.lastName.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>TITLE</p>
                      <select name='title' id='' {...personalRegister('title')}>
                        <option value={`${taxPayerData?.title}`}>
                          {taxPayerData?.title === 'Mr.' ? 'Mr.' : 'Mrs.'}
                        </option>
                        {taxPayerData?.title === 'Mr.' ? (
                          <option value='Mrs'>Mrs.</option>
                        ) : (
                          <option value='Mr'>Mr.</option>
                        )}
                      </select>
                      {personalError.title && (
                        <h6 className='vError'>
                          {personalError.title.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>MARITAL STATUS</p>
                      <input
                        type='text'
                        name='maritalStatus'
                        {...personalRegister('maritalStatus')}
                        defaultValue={taxPayerData?.maritalStatus}
                      />
                      {personalError.maritalStatus && (
                        <h6 className='vError'>
                          {personalError.maritalStatus.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>DATE OF BIRTH</p>
                      <input
                        type='date'
                        name='dateOfBirth'
                        {...personalRegister('dateOfBirth')}
                        defaultValue={
                          taxPayerData?.dateOfBirth
                            ? taxPayerData?.dateOfBirth.slice(0, 10)
                            : ''
                        }
                      />
                      {personalError.dateOfBirth && (
                        <h6 className='vError'>
                          {personalError.dateOfBirth.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>OCCUPATION</p>
                      <input
                        type='text'
                        name='occupation'
                        {...residencyRegister('occupation')}
                        defaultValue={taxPayerData?.occupation}
                      />
                      {residencyError.occupation && (
                        <h6 className='vError'>
                          {residencyError.occupation.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>NIN</p>
                      <input
                        type='number'
                        name='nin'
                        defaultValue={taxPayerData?.nin}
                        {...personalRegister('nin')}
                      />
                      {personalError.nin && (
                        <h6 className='vError'>{personalError.nin.message}</h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>TIN</p>
                      <input
                        type='number'
                        name='tin'
                        {...personalRegister('tin')}
                        defaultValue={taxPayerData?.tin}
                      />
                      {personalError.tin && (
                        <h6 className='vError'>{personalError.tin.message}</h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>ODTIN</p>
                      <input
                        type='number'
                        name='odTin'
                        {...personalRegister('odTin')}
                        defaultValue={taxPayerData?.odTin}
                      />
                      {personalError.odTin && (
                        <h6 className='vError'>
                          {personalError.odTin.message}
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className='top_four'>
                    <div className='cards'>
                      <p>CONTACT PHONE NUMBER</p>
                      <input
                        type='tel'
                        name='phone'
                        {...personalRegister('phone')}
                        defaultValue={taxPayerData?.phone}
                      />
                      {personalError.phone && (
                        <h6 className='vError'>
                          {personalError.phone.message}
                        </h6>
                      )}
                    </div>
                    <div className='cards'>
                      <p>EMAIL ADDRESS</p>
                      <input
                        type='email'
                        name='email'
                        {...personalRegister('email')}
                        defaultValue={taxPayerData?.email}
                      />
                      {personalError.email && (
                        <h6 className='vError'>
                          {personalError.email.message}
                        </h6>
                      )}
                    </div>
                  </div>
                  {Object.keys(taxPayerData).includes(
                    'identificationDocument'
                  ) ? (
                    <>
                      {Object.keys(taxPayerData?.identificationDocument)
                        .length === 0 ? (
                        <div className='top_six'>
                          <div className='card'>
                            <p>VALID ID CARD</p>
                            <select
                              name='id_type'
                              {...personalRegister('id_type')}
                            >
                              <option value='National Identity'>
                                National Identity
                              </option>
                              <option value='Voters Card'>Voters Card</option>
                              <option value='Drivers License'>
                                Drivers License
                              </option>
                            </select>
                            {personalError.id_type && (
                              <h6 className='vError'>
                                {personalError.id_type.message}
                              </h6>
                            )}
                          </div>
                          <div className='card'>
                            <p>ID NUMBER</p>
                            <input
                              type='number'
                              name='id_number'
                              {...personalRegister('id_number')}
                            />
                            {personalError.id_number && (
                              <h6 className='vError'>
                                {personalError.id_number.message}
                              </h6>
                            )}
                          </div>
                          <div className='card'>
                            <p>ID EXPIRY DATE</p>
                            <input
                              type='date'
                              name='id_date'
                              {...personalRegister('id_date')}
                            />
                            {personalError.id_date && (
                              <h6 className='vError'>
                                {personalError.id_date.message}
                              </h6>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className='top_six'>
                          <div className='card'>
                            <p>VALID ID CARD</p>
                            <select
                              name='id_type'
                              {...personalRegister('id_type')}
                            >
                              <option
                                value={`${taxPayerData?.identificationDocument.type}`}
                              >
                                {taxPayerData?.identificationDocument.type ===
                                  'National Identity' && 'National Identity'}
                                {taxPayerData?.identificationDocument.type ===
                                  'Voters Card' && 'Voters Card'}
                                {taxPayerData?.identificationDocument.type ===
                                  'Drivers License' && 'Drivers License'}
                              </option>
                              {taxPayerData?.identificationDocument.type ===
                                'National Identity' && (
                                <>
                                  <option value='Voters Card'>
                                    Voters Card
                                  </option>
                                  <option value='Drivers License'>
                                    Drivers License
                                  </option>
                                </>
                              )}
                              {taxPayerData?.identificationDocument.type ===
                                'Voters Card' && (
                                <>
                                  <option value='National Identity'>
                                    National Identity
                                  </option>
                                  <option value='Drivers License'>
                                    Drivers License
                                  </option>
                                </>
                              )}
                              {taxPayerData?.identificationDocument.type ===
                                'Drivers License' && (
                                <>
                                  <option value='Voters Card'>
                                    Voters Card
                                  </option>
                                  <option value='National Identity'>
                                    National Identity
                                  </option>
                                </>
                              )}
                            </select>
                            {personalError.id_type && (
                              <h6 className='vError'>
                                {personalError.id_type.message}
                              </h6>
                            )}
                          </div>
                          <div className='card'>
                            <p>ID NUMBER</p>
                            <input
                              type='number'
                              name='id_number'
                              {...personalRegister('id_number')}
                              defaultValue={
                                taxPayerData?.identificationDocument
                                  .documentNumber
                              }
                            />
                            {personalError.id_number && (
                              <h6 className='vError'>
                                {personalError.id_number.message}
                              </h6>
                            )}
                          </div>
                          <div className='card'>
                            <p>ID EXPIRY DATE</p>
                            <input
                              type='date'
                              name='id_date'
                              {...personalRegister('id_date')}
                              defaultValue={taxPayerData?.identificationDocument.expiryDate.slice(
                                0,
                                10
                              )}
                            />
                            {personalError.id_date && (
                              <h6 className='vError'>
                                {personalError.id_date.message}
                              </h6>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className='top_six'>
                      <div className='card'>
                        <p>VALID ID CARD</p>
                        <select name='id_type' {...personalRegister('id_type')}>
                          <option value='National Identity'>
                            National Identity
                          </option>
                          <option value='Voters Card'>Voters Card</option>
                          <option value='Drivers License'>
                            Drivers License
                          </option>
                        </select>
                        {personalError.id_type && (
                          <h6 className='vError'>
                            {personalError.id_type.message}
                          </h6>
                        )}
                      </div>
                      <div className='card'>
                        <p>ID NUMBER</p>
                        <input
                          type='number'
                          name='id_number'
                          {...personalRegister('id_number')}
                        />
                        {personalError.id_number && (
                          <h6 className='vError'>
                            {personalError.id_number.message}
                          </h6>
                        )}
                      </div>
                      <div className='card'>
                        <p>ID EXPIRY DATE</p>
                        <input
                          type='date'
                          name='id_date'
                          {...personalRegister('id_date')}
                        />
                        {personalError.id_date && (
                          <h6 className='vError'>
                            {personalError.id_date.message}
                          </h6>
                        )}
                      </div>
                    </div>
                  )}

                  <div className='buttonx'>
                    <button>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {informationPage === 'residency' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <MdOutlineHouse />
                </div>
                <h2>Residency Status</h2>
              </div>
              <div className='info_wrap'>
                <form
                  onSubmit={residencyHandleSubmit(onResidencySubmit)}
                  className='top_form'
                >
                  <div className='address'>
                    <p>CURRENT RESIDENTIAL ADDRESS</p>
                    <input
                      type='text'
                      name='address'
                      {...residencyRegister('address')}
                      defaultValue={taxPayerData?.residency.address}
                    />
                    {residencyError.address && (
                      <h6 className='vError'>
                        {residencyError.address.message}
                      </h6>
                    )}
                  </div>
                  <div className='top_six'>
                    <div className='card'>
                      <p>NEAREST BUSTOP</p>
                      <input
                        type='text'
                        name='nearestBusStop'
                        {...residencyRegister('nearestBusStop')}
                        defaultValue={taxPayerData?.residency.nearestBusStop}
                      />
                      {residencyError.nearestBusStop && (
                        <h6 className='vError'>
                          {residencyError.nearestBusStop.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>CITY</p>
                      <input
                        type='text'
                        name='city'
                        {...residencyRegister('city')}
                        defaultValue={taxPayerData?.residency.city}
                      />
                      {residencyError.city && (
                        <h6 className='vError'>
                          {residencyError.city.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>LOCAL GOVT. AREA</p>
                      <input
                        type='text'
                        name='lga'
                        {...residencyRegister('lga')}
                        defaultValue={taxPayerData?.residency.lga}
                      />
                      {residencyError.lga && (
                        <h6 className='vError'>{residencyError.lga.message}</h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>STATE</p>
                      <input
                        type='text'
                        name='state'
                        {...residencyRegister('state')}
                        defaultValue={taxPayerData?.residency.state}
                      />
                      {residencyError.state && (
                        <h6 className='vError'>
                          {residencyError.state.message}
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className='top_six'>
                    <div className='card'>
                      <p>RESIDENT TYPE</p>
                      <select
                        name='residentType'
                        {...residencyRegister('residentType')}
                      >
                        <option
                          value={`${taxPayerData?.residency.residentType}`}
                        >
                          {taxPayerData?.residency.residentType === 'owner'
                            ? 'Owner'
                            : 'Tenant'}
                        </option>
                        {taxPayerData?.residency.residentType === 'owner' ? (
                          <option value='tenant'>Tenant</option>
                        ) : (
                          <option value='owner'>Owner</option>
                        )}
                      </select>
                      {residencyError.residentType && (
                        <h6 className='vError'>
                          {residencyError.residentType.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>YEARS OF STAY</p>
                      <input
                        type='number'
                        name='yearsOfStay'
                        {...residencyRegister('yearsOfStay')}
                        defaultValue={taxPayerData?.residency.yearsOfStay}
                      />
                      {residencyError.yearsOfStay && (
                        <h6 className='vError'>
                          {residencyError.yearsOfStay.message}
                        </h6>
                      )}
                    </div>
                    <div className='card'>
                      <p>NO. OF NEIGHBOURS/TENANTS</p>
                      <input
                        type='number'
                        name='numberOfNeighbours'
                        {...residencyRegister('numberOfNeighbours')}
                        defaultValue={
                          taxPayerData?.residency.numberOfNeighbours
                        }
                      />
                      {residencyError.numberOfNeighbours && (
                        <h6 className='vError'>
                          {residencyError.numberOfNeighbours.message}
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className='buttonx'>
                    <button>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {/* {informationPage === 'employement' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <BsBuilding />
                </div>
                <h2>Employment Information</h2>
              </div>
              <div className='info_wrap'>
                <div className='top_form'>
                  <div className='top_four'>
                    <div className='cards'>
                      <p>BUSINESS NAME</p>
                      <input type='text' name='' id='' />
                    </div>
                    <div className='cards'>
                      <p>BUSINESS ADDRESS</p>
                      <input type='text' name='' id='' />
                    </div>
                  </div>
                  <div className='top_six'>
                    <div className='card'>
                      <p>TYPE OF EMPLOYMENT</p>
                      <input type='text' name='' id='' />
                    </div>
                    <div className='card'>
                      <p>EMPLOYER EMAIL</p>
                      <input type='email' name='' id='' />
                    </div>
                    <div className='card'>
                      <p>NUMBER OF EMPLOYEE</p>
                      <input type='number' name='' id='' />
                    </div>
                  </div>
                  <div className='buttonx'>
                    <button>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {informationPage === 'spouse' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <MdOutlineFamilyRestroom />
                </div>
                <h2>Spouse and Kids Information</h2>
              </div>
              <div className='info_wrap'>
                <form
                  className='top_form'
                  onSubmit={SpouceHandleSubmit(onSpouseSubmit)}
                >
                  {Object.keys(taxPayerData).includes('spouse') ? (
                    <>
                      {Object.keys(taxPayerData?.spouse).length === 0 ? (
                        <>
                          <div className='top_six'>
                            <div className='card'>
                              <p>FIRST NAME</p>
                              <input
                                type='text'
                                name='spouseName1'
                                {...SpouceRegister('spouseName1')}
                              />
                            </div>
                            <div className='card'>
                              <p>LAST NAME</p>
                              <input
                                type='text'
                                name='spouseName2'
                                {...SpouceRegister('spouseName2')}
                              />
                            </div>
                            <div className='card'>
                              <p>DATE OF BIRTH</p>
                              <input
                                type='date'
                                name='spouseDOB'
                                {...SpouceRegister('spouseDOB')}
                              />
                            </div>
                          </div>
                          <div className='top_four'>
                            <div className='cards'>
                              <p>EMPLOYER/BUSINESS</p>
                              <input
                                type='text'
                                name='spouseBiz'
                                {...SpouceRegister('spouseBiz')}
                              />
                            </div>
                            <div className='cards'>
                              <p>Spouse TIN</p>
                              <input
                                type='nmber'
                                name='spouseTin'
                                {...SpouceRegister('spouseTin')}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='top_six'>
                            <div className='card'>
                              <p>FIRST NAME</p>
                              <input
                                type='text'
                                name='spouseName1'
                                defaultValue={taxPayerData?.spouse.firstName}
                                {...SpouceRegister('spouseName1')}
                              />
                            </div>
                            <div className='card'>
                              <p>LAST NAME</p>
                              <input
                                type='text'
                                name='spouseName2'
                                defaultValue={taxPayerData?.spouse.lastName}
                                {...SpouceRegister('spouseName2')}
                              />
                            </div>
                            <div className='card'>
                              <p>DATE OF BIRTH</p>
                              <input
                                type='date'
                                name='spouseDOB'
                                defaultValue={taxPayerData?.spouse.dateOfBirth.slice(
                                  0,
                                  10
                                )}
                                {...SpouceRegister('spouseDOB')}
                              />
                            </div>
                          </div>
                          <div className='top_four'>
                            <div className='cards'>
                              <p>EMPLOYER/BUSINESS</p>
                              <input
                                type='text'
                                name='spouseBiz'
                                defaultValue={taxPayerData?.spouse.employerName}
                                {...SpouceRegister('spouseBiz')}
                              />
                            </div>
                            <div className='cards'>
                              <p>Spouse TIN</p>
                              <input
                                type='nmber'
                                name='spouseTin'
                                defaultValue={taxPayerData?.spouse.tin}
                                {...SpouceRegister('spouseTin')}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='top_six'>
                        <div className='card'>
                          <p>FIRST NAME</p>
                          <input
                            type='text'
                            name='spouseName1'
                            {...SpouceRegister('spouseName1')}
                          />
                        </div>
                        <div className='card'>
                          <p>LAST NAME</p>
                          <input
                            type='text'
                            name='spouseName2'
                            {...SpouceRegister('spouseName2')}
                          />
                        </div>
                        <div className='card'>
                          <p>DATE OF BIRTH</p>
                          <input
                            type='date'
                            name='spouseDOB'
                            {...SpouceRegister('spouseDOB')}
                          />
                        </div>
                      </div>
                      <div className='top_four'>
                        <div className='cards'>
                          <p>EMPLOYER/BUSINESS</p>
                          <input
                            type='text'
                            name='spouseBiz'
                            {...SpouceRegister('spouseBiz')}
                          />
                        </div>
                        <div className='cards'>
                          <p>Spouse TIN</p>
                          <input
                            type='nmber'
                            name='spouseTin'
                            {...SpouceRegister('spouseTin')}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {childInfo.map((form, index) => (
                    <div className='child_add' key={index}>
                      <div className='cards'>
                        <p>CHILD FIRST NAME</p>
                        <input
                          type='text'
                          name='firstName'
                          onChange={(event) =>
                            handleChildFormChange(event, index)
                          }
                          defaultValue={form.firstName}
                        />
                      </div>
                      <div className='cards'>
                        <p>CHILD LAST NAME</p>
                        <input
                          type='text'
                          name='lastName'
                          onChange={(event) =>
                            handleChildFormChange(event, index)
                          }
                          defaultValue={form.lastName}
                        />
                      </div>
                      <div
                        className='remove-butt'
                        onClick={() => removeChildFields(index)}
                      >
                        remove
                      </div>
                    </div>
                  ))}
                  <div className='child_button'>
                    <div className='button' onClick={() => addChildFields()}>
                      <p>Add another Child</p>
                    </div>
                  </div>
                  <div className='buttonx'>
                    <button>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {informationPage === 'tax' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'>
                    <div className='icon'>
                      <TbReceiptTax />
                    </div>
                    <h2>TAX Information</h2>
                  </div>
                  <div className='two'>
                    <button className='active'>Income tax</button>
                    <button>LUC</button>
                    <button>Capital Gains</button>
                  </div>
                </div>
              </div>
              <div className='info-table'>
                <table>
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Tax paid</th>
                      <th>Tax Period</th>
                      <th>Open Cases</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(3)].map((item) => (
                      <tr>
                        <td>January</td>
                        <td>NGN 23,746.07</td>
                        <td>3rd February</td>
                        <td>0 Case</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {informationPage === 'assets' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'></div>
                  <div className='two'>
                    <button className='active'>Vehicle</button>
                    {/* <button>Houses</button> */}
                    {/* <button>Gifts</button> */}
                  </div>
                </div>
              </div>
              <div className='info_wrap'>
                <>
                  <div className='top_form'>
                    {assestsInfo.map((form, index) => (
                      <div className='top_six'>
                        <div className='card'>
                          <p>Vehicle ID</p>
                          <input
                            type='text'
                            name='vehicleId'
                            id=''
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.applicationId}
                          />
                        </div>
                        <div className='card'>
                          <p>ENGINE NO</p>
                          <input
                            type='text'
                            name='engineNo'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.engineNo}
                          />
                        </div>
                        <div className='card'>
                          <p>CHASIS NO</p>
                          <input
                            type='text'
                            name='chasisNo'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.chasisNumber}
                          />
                        </div>
                        <div className='card'>
                          <p>CATEGORY</p>
                          <input
                            type='text'
                            name='category'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.category}
                          />
                        </div>
                        <div className='card'>
                          <p>MAKE</p>
                          <input
                            type='text'
                            name='make'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.make}
                          />
                        </div>
                        <div className='card'>
                          <p>MODEL</p>
                          <input
                            type='text'
                            name='model'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.model}
                          />
                        </div>
                        <div className='card'>
                          <p>type</p>
                          <input
                            type='text'
                            name='type'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.type}
                          />
                        </div>
                        <div className='card'>
                          <p>color</p>
                          <input
                            type='text'
                            name='color'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.color}
                          />
                        </div>
                        <div className='card'>
                          <p>purpose</p>
                          <input
                            type='text'
                            name='purpose'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.purpose}
                          />
                        </div>
                        <div className='card'>
                          <p>capacity</p>
                          <input
                            type='text'
                            name='capacity'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.capacity}
                          />
                        </div>
                        <div className='card'>
                          <p>plate Number</p>
                          <input
                            type='text'
                            name='plateNumber'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.plateNumber}
                          />
                        </div>
                        <div className='card'>
                          <p>address</p>
                          <input
                            type='text'
                            name='address'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.address}
                          />
                        </div>
                        <div className='card'>
                          <p>reg Description</p>
                          <input
                            type='text'
                            name='regDescription'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.description}
                          />
                        </div>
                        <div className='card'>
                          <p>year of manufacture</p>
                          <input
                            type='text'
                            name='year'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.year}
                          />
                        </div>
                        <div className='card'>
                          <p>officer</p>
                          <input
                            type='text'
                            name='officer'
                            onChange={(event) =>
                              handleAssestFormChange(event, index)
                            }
                            defaultValue={form.officer}
                          />
                        </div>
                        <div className='card'>
                          <p>.</p>
                          <button onClick={removeAssestsFields}>
                            Remove Vehicle
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className='child_button'>
                      <div
                        className='button'
                        onClick={() => addAssestsFields()}
                      >
                        <p>Add another vehicle</p>
                      </div>
                    </div>
                    <div className='buttonx'>
                      <button onClick={onVehcileSubmit}>Submit</button>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={SideBar ? 'right' : 'right active'}>
        <div className='close'>
          <TbArrowBarRight onClick={() => setSideBar(!SideBar)} />
        </div>
        <div className='tag'>
          <h1>Edit Information</h1>
        </div>
        <div className='links'>
          <ul>
            <li
              className={informationPage === 'personal' ? 'active' : ''}
              onClick={() => setInformationPage('personal')}
            >
              Personal Information
            </li>
            <li
              className={informationPage === 'residency' ? 'active' : ''}
              onClick={() => setInformationPage('residency')}
            >
              Residency Status
            </li>
            {/* <li
              className={informationPage === 'employement' ? 'active' : ''}
              onClick={() => setInformationPage('employement')}
            >
              Employement information
            </li> */}
            {/* <li
              className={informationPage === 'tax' ? 'active' : ''}
              onClick={() => setInformationPage('tax')}
            >
              TAX Information
            </li> */}

            <li
              className={informationPage === 'spouse' ? 'active' : ''}
              onClick={() => setInformationPage('spouse')}
            >
              Spouse and Kids Information
            </li>
            <li
              className={informationPage === 'assets' ? 'active' : ''}
              onClick={() => setInformationPage('assets')}
            >
              Assets
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Edit
