import React, { useState } from 'react'
import Logo from '../../Images/logo.png'
import Loader from '../../Images/loader.gif'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useForm } from 'react-hook-form'

function Otp({ Login, notify }) {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()
  const onLogin = (formData) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/auth/login/otp`, {
      method: 'post',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify({
        otp: formData.otp,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            Login({ ...data.tokens })
            sessionStorage.setItem('AdminIdy', data.user.id)
            notify('success', 'Admin Verified.')
            setLoading(false)
            setTimeout(() => {
              window.location.replace('/dashboard/home')
            }, 2000)
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setLoading(false)
          })
        }
      })

      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setLoading(false)
        // setFormLoading(false)
      })
  }
  return (
    <div className='login'>
      <div className='center'>
        <div className='left'>
          <div className='card'>
            <h3> Welcome to Ondo State</h3>
            <h1>Electronic Data Management System (EDMS/BIG DATA)</h1>
            <h5>
              Please note that this system comes with a restricted access. Any
              unauthorised access to this system will be denied.{' '}
            </h5>
            <div className='det'>
              <p>For futher enquiries, contact us at ODRIS:</p>
              <p>0818-839-700 | 0902 847 7004 | 0810 486 4804. </p>
            </div>
          </div>
        </div>
        <div className='right'>
          <div className='log'>
            <img src={Logo} alt='' />
          </div>
          <form className='form' action='' onSubmit={handleSubmit(onLogin)}>
            <div className='form_head'>
              <div className='cav'>
                <h1>OTP Verification</h1>
              </div>
            </div>
            <div className='form_body'>
              <div className='card'>
                <input
                  type='text'
                  placeholder='PIN/User ID'
                  name='otp'
                  {...register('otp', {
                    required: 'OTP is required',
                  })}
                />
                {errors.otp && <h6 className='vError'>{errors.otp.message}</h6>}
              </div>
            </div>

            {loading ? (
              <div className='loaderx'>
                <img src={Loader} alt='' />
              </div>
            ) : (
              <div className='form_bottom'>
                <button>
                  <p>Verify</p>
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Otp
