import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss'
import Login from './Pages/Auth/Login'
import Dashboard from './Pages/Dashboard/Dashboard'
import ScrollToTop from './Scroll.js'
import ProtectedRoute from './ProtectedRoute'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Otp from './Pages/Auth/Otp.js'
import Forgot from './Pages/Auth/Forgot.js'
import ResetPassword from './Pages/Auth/ResetPasswor.js'

function App() {
  const notify = (type, message) => {
    if (type === 'info') {
      toast.info(message)
    }
    if (type === 'success') {
      toast.success(message)
    }
    if (type === 'warn') {
      toast.warn(message)
    }
    if (type === 'error') {
      toast.error(message)
    }
  }
  const [loggedIn, setLoggedIn] = useState(false)
  const logAdminIn = (admin) => {
    sessionStorage.setItem('AdminId', admin.access.token)
    sessionStorage.setItem('Admin-Id', admin.refresh.token)
    setLoggedIn(true)
  }
  const logOut = () => {
    sessionStorage.removeItem('AdminId')
    sessionStorage.removeItem('Admin-Id')
    sessionStorage.removeItem('AdminIdy')
    setLoggedIn(false)
  }
  return (
    <BrowserRouter>
      <ToastContainer
        position='top-right'
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ScrollToTop />
      <Routes>
        <Route
          path='/'
          element={<Login Login={logAdminIn} notify={notify} />}
        />
        <Route
          path='/forgot-password'
          element={<Forgot Login={logAdminIn} notify={notify} />}
        />
        <Route
          path='/reset-password'
          element={<ResetPassword Login={logAdminIn} notify={notify} />}
        />
        <Route
          path='/otp-verification'
          element={<Otp Login={logAdminIn} notify={notify} />}
        />
        <Route element={<ProtectedRoute loggedIn={loggedIn} notify={notify} />}>
          <Route
            path='/dashboard/:page'
            element={<Dashboard notify={notify} logOut={logOut} />}
          />
          <Route
            path='/dashboard/:page/:id'
            element={<Dashboard notify={notify} logOut={logOut} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
