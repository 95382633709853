import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import API from '../../../../Utility/API/API'
import Loader from '../../../../Images/loader.gif'
import { BsArrowLeft } from 'react-icons/bs'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import EditFileData from './EditFileData'

function GeneralData({ notify }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [selecetedTransaction, setSelecetedTransaction] = useState({})
  const { handleLinkData } = useGlobalContext()
  const [showEdit, setShowEdit] = useState(false)

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const [DataValue, setDataValue] = useState({})

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/file-data/${id}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedTransaction(data)
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  function isLink(value) {
    // Regular expression to check if a value looks like a URL
    const urlPattern = /^(http|https):\/\/\w+/i
    return urlPattern.test(value)
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='indtransaction'>
      {showEdit && (
        <div className='modals'>
          <EditFileData
            selecetedTransaction={selecetedTransaction}
            notify={notify}
            setShowEdit={setShowEdit}
          />
        </div>
      )}
      <div className='top_button'>
        {selecetedTransaction.hasOwnProperty('taxPayerId') ? (
          <button
            onClick={() =>
              navigate(
                `/dashboard/tax_payers/${selecetedTransaction.taxPayerId}`
              )
            }
          >
            View Tax Payer
          </button>
        ) : (
          <div className='top_button'>
            <button onClick={() => handleLinkData(selecetedTransaction)}>
              Link to Taxpayer
            </button>
          </div>
        )}
      </div>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='transactions_wrap'>
        <div className='list1'>
          <div className='card'>
            <p>Category</p>
            <h2>{selecetedTransaction.category}</h2>
          </div>
          {Object.entries(selecetedTransaction.fileData).map((item, i) => (
            <div className='card' key={i + 1}>
              <p>{item[0]}</p>
              {isLink(item[1]) ? (
                <a href={item[1]} target='_blank'>
                  <button>View</button>
                </a>
              ) : (
                <h2>{item[1]}</h2>
              )}
            </div>
          ))}
        </div>
        <div className='button-part'>
          <button onClick={() => setShowEdit(true)}>Edit Data</button>
        </div>
      </div>
    </div>
  )
}

export default GeneralData
