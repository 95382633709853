import React, { useState, useEffect } from 'react'
import './CorporateUser.scss'
import API from '../../../../Utility/API/API'
import {
  BsArrowLeft,
  BsThreeDotsVertical,
  BsFileEarmarkText,
} from 'react-icons/bs'
import { TbArrowBarLeft, TbArrowBarRight, TbReceiptTax } from 'react-icons/tb'
import { useNavigate, useParams } from 'react-router-dom'
import Capital from '../../../../Images/capital.png'
import Pdf from '../../../../Images/pdf.png'
import LoaderComp from '../../Component/Loader/LoaderComp'
import { useGlobalContext } from '../../../../Utility/Context/Context'

function CorporateUser({ IsUpload }) {
  const [SideBar, setSideBar] = useState('false')
  const [informationPage, setInformationPage] = useState('registration')
  const navigate = useNavigate()
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [selecetedCoporate, setSelecetedCoporate] = useState({})
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const { handleAddSocialToggle, handleLinkData } = useGlobalContext()

  useEffect(() => {
    API.FetchIndCorporate(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedCoporate(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <LoaderComp />
  ) : (
    <div className='corporateUser'>
      <div className='left'>
        <div className='top'>
          <div></div>
          <div className='burger'>
            <TbArrowBarLeft onClick={() => setSideBar(!SideBar)} />
          </div>
        </div>
        <div className='back'>
          <div className='back_left'>
            <BsArrowLeft onClick={() => navigate(-1)} />
            <h1>PAYE Registration</h1>
          </div>
          <div className='back_right'>
            <p>Enrollee ID: {selecetedCoporate.registrationNumber}</p>
          </div>
        </div>
        <div className='user_details'>
          <div className='user_left'>
            <img src={Capital} alt='' />
          </div>
          <div className='user_right'>
            <div className='card'>
              <p>Company name</p>
              <h2>{selecetedCoporate.businessName}</h2>
            </div>
            <div className='card'>
              <p>Trade Name</p>
              <h2>{selecetedCoporate.tradeName}</h2>
            </div>
            <div className='card'>
              <p>Email Address</p>
              <h2>
                {selecetedCoporate.email === null
                  ? 'Not Set'
                  : selecetedCoporate.email}
              </h2>
            </div>
            <div className='card'>
              <p>Phone Number</p>
              <h2>
                {selecetedCoporate.phone.length < 1
                  ? 'Not Set'
                  : selecetedCoporate.phone}
              </h2>
            </div>
          </div>
        </div>
        <div className='information_page'>
          {informationPage === 'registration' && (
            <div className='info'>
              <div className='info_top'>
                <div className='icon'>
                  <BsFileEarmarkText />
                </div>
                <h2>Registration Information</h2>
              </div>
              <div className='info_wrap'>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>Date Of Incorporation</p>
                    <h2>
                      {new Date(
                        selecetedCoporate.dateOfIncorporation
                      ).toDateString()}
                    </h2>
                  </div>
                  <div className='card'>
                    <p>Commencement Date</p>
                    <h2>
                      {new Date(
                        selecetedCoporate.commencementDate
                      ).toDateString()}
                    </h2>
                  </div>
                  <div className='card'>
                    <p>Registration Date</p>
                    <h2>
                      {new Date(
                        selecetedCoporate.registrationDate
                      ).toDateString()}
                    </h2>
                  </div>
                </div>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>Organisation Type</p>
                    <h2>{selecetedCoporate.organisationType}</h2>
                  </div>
                  <div className='card'>
                    <p>Line Of Business</p>
                    <h2>{selecetedCoporate.lineOfBusiness}</h2>
                  </div>
                  <div className='card'>
                    <p>Number Of Employees</p>
                    <h2>
                      {!selecetedCoporate.numberOfEmployees
                        ? 'Not Set'
                        : selecetedCoporate.numberOfEmployees}
                    </h2>
                  </div>
                </div>
                <div className='four_wrap'>
                  <div className='card'>
                    <p>House No</p>
                    <h2>{selecetedCoporate.houseNo}</h2>
                  </div>
                  <div className='card'>
                    <p>Street</p>
                    <h2>
                      {' '}
                      {selecetedCoporate.street === null
                        ? 'Not Set'
                        : selecetedCoporate.street}
                    </h2>
                  </div>
                  <div className='card'>
                    <p>City</p>
                    <h2>
                      {' '}
                      {selecetedCoporate.city === null
                        ? 'Not Set'
                        : selecetedCoporate.city}
                    </h2>
                  </div>
                  <div className='card'>
                    <p>State</p>
                    <h2>{selecetedCoporate.state}</h2>
                  </div>
                </div>
                <div className='single_wrap'>
                  <p>Tax Authority</p>
                  <h2>
                    {selecetedCoporate.taxAuthority === null
                      ? 'Not Set'
                      : selecetedCoporate.taxAuthority}
                  </h2>
                </div>
              </div>
            </div>
          )}
          {informationPage === 'documents' && (
            <div className='info'>
              <div className='info-head'>
                <div className='info_top dif'>
                  <div className='icon'>
                    <BsFileEarmarkText />
                  </div>
                  <h2>Social Media</h2>
                </div>
                {IsUpload && (
                  <button className='save' onClick={handleAddSocialToggle}>
                    Add Social media
                  </button>
                )}
              </div>
              <div className='info-table'>
                <table>
                  <thead>
                    <tr>
                      <th>Platform</th>
                      <th>Content</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(selecetedCoporate).includes('socialPosts') && (
                      <>
                        {selecetedCoporate.socialPosts.map((item, i) => (
                          <tr key={i + 1}>
                            <td>{item.platform}</td>
                            <td>{item.content}</td>
                            <td>
                              <a
                                href={item.postUrl}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <button>Visit</button>
                              </a>
                              {item.mediaUrls.map((link, i) => (
                                <a
                                  href={link}
                                  target='_blank'
                                  rel='noreferrer'
                                  key={i}
                                >
                                  <button>Media</button>
                                </a>
                              ))}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {informationPage === 'tax' && (
            <div className='info'>
              <div className='info_top'>
                <div className='info-top_wrap'>
                  <div className='one'>
                    <div className='icon'>
                      <TbReceiptTax />
                    </div>
                    <h2>Tax infirmation</h2>
                  </div>
                  <div className='two'>
                    <button className='active'>Income tax</button>
                    <button>LUC</button>
                    <button>Capital Gains</button>
                  </div>
                </div>
              </div>
              <div className='info-table'>
                <table>
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Tax paid</th>
                      <th>Tax Period</th>
                      <th>Open Cases</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(3)].map((item) => (
                      <tr>
                        <td>January</td>
                        <td>NGN 23,746.07</td>
                        <td>3rd February</td>
                        <td>0 Case</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={SideBar ? 'right' : 'right active'}>
        <div className='close'>
          <TbArrowBarRight onClick={() => setSideBar(!SideBar)} />
        </div>
        <div className='top'>
          <button
            onClick={() =>
              navigate(`/dashboard/edit_corporate/${selecetedCoporate.id}`)
            }
          >
            Edit Corporate
          </button>
        </div>
        <div className='tag'>
          <h1>More Information</h1>
        </div>
        <div className='links'>
          <ul>
            <li
              className={informationPage === 'registration' ? 'active' : ''}
              onClick={() => setInformationPage('registration')}
            >
              Registration Info
            </li>
            <li
              className={informationPage === 'documents' ? 'active' : ''}
              onClick={() => setInformationPage('documents')}
            >
              Social Media
            </li>
            <li
              className={informationPage === 'link' ? 'active' : ''}
              onClick={() => {
                setInformationPage('link')
                handleLinkData(selecetedCoporate)
              }}
            >
              Link to Taxpayer
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CorporateUser
