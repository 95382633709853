import React, { useEffect, useState } from 'react'
import API from '../../../../Utility/API/API'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import Pagination from '../../Component/Pagination/Pagination'

function AuditTrail({ notify }) {
  const [loading, setLoading] = useState(true)
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const [auditLogs, setAuditLogs] = useState({})
  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate()
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchAuditTrail(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setAuditLogs(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    setCurrentPage(num)
    API.FetchAuditTrail(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setAuditLogs(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='taxPayer'>
      <div className='top'>
        <h1>Audit Trail</h1>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th> Action</th>
              <th> Date/Time</th>
              <th> Record type</th>
            </tr>
          </thead>
          <tbody>
            {auditLogs.results.map((item, i) => (
              <tr key={item.id}>
                <td>
                  {item.user.firstName} {item.user.lastName}
                </td>
                <td> {item.user.email}</td>
                <td className='status'>
                  <p> {item.action}</p>
                </td>
                <td>
                  {new Date(item.createdAt).toDateString()}{' '}
                  {new Date(item.createdAt).toLocaleTimeString()}
                </td>
                <td> {item.recordType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={auditLogs.totalPages}
        pageSize={10}
        onPageChange={(page) => {
          setCurrentPage(page)
          paginationClick(page)
        }}
      />
    </div>
  )
}

export default AuditTrail
