import React, { useState, useEffect } from 'react'
import './EnrollPayer.scss'
import { useNavigate } from 'react-router-dom'
import {
  BsArrowLeft,
  BsCheckCircleFill,
  BsCamera,
  BsArrowRight,
  BsAsterisk,
} from 'react-icons/bs'
import { RiUser3Line, RiGroupLine } from 'react-icons/ri'
import { MdOutlineBusiness } from 'react-icons/md'
import { TbReceiptTax } from 'react-icons/tb'
import { useForm } from 'react-hook-form'
import Loader from '../../../../Images/loader.gif'
import StateData from './StateData'

function EnrollPayer({ notify }) {
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const [enrollPage, setEnrollPage] = useState('personal')
  const [houseType, setHouseType] = useState('')
  const [idType, setIdType] = useState('')
  const navigate = useNavigate()
  const [profilePicture, setProfilePicture] = useState({
    string: '',
    file: null,
  })
  const [imgTru, setImgTru] = useState(false)
  const [localGovernment, setLocalGovernment] = useState([])
  const onSelectProductImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 2000000) {
      notify('error', 'Image must be bellow 1mb')
    } else {
      reader.onloadend = () => {
        setImgTru(true)
        setProfilePicture({
          string: reader.result,
          file,
        })
      }
    }
  }
  // this us the new line
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const {
    register: TaxRegister,
    handleSubmit: TaxSubmit,
    formState: { errors: TaxErrors },
  } = useForm()
  const [childInfo, setChildInfo] = useState([{ firstName: '', lastName: '' }])
  const [assestsInfo, setAssestsInfo] = useState([
    {
      vehicleId: '',
      engineNo: '',
      chasisNo: '',
      category: '',
      make: '',
      model: '',
      type: '',
      color: '',
      purpose: '',
      capacity: '',
      plateNumber: '',
      ownerName: '',
      address: '',
      regDate: '',
      regDescription: '',
      year: '',
      officer: '',
    },
  ])
  const handleChildFormChange = (event, index) => {
    let data = [...childInfo]
    data[index][event.target.name] = event.target.value
    setChildInfo(data)
  }
  const handleAssestFormChange = (event, index) => {
    let data = [...assestsInfo]
    data[index][event.target.name] = event.target.value
    setAssestsInfo(data)
  }
  const addChildFields = () => {
    let object = {
      firstName: '',
      lastName: '',
    }
    setChildInfo([...childInfo, object])
  }
  const removeChildFields = (index) => {
    let data = [...childInfo]
    data.splice(index, 1)
    setChildInfo(data)
  }
  const addAssestsFields = () => {
    let object = {
      vehicleId: '',
      engineNo: '',
      chasisNo: '',
      category: '',
      make: '',
      model: '',
      type: '',
      color: '',
      purpose: '',
      capacity: '',
      plateNumber: '',
      ownerName: '',
      address: '',
      regDate: '',
      regDescription: '',
      year: '',
      officer: '',
    }
    setAssestsInfo([...assestsInfo, object])
  }
  const removeAssestsFields = (index) => {
    let data = [...assestsInfo]
    data.splice(index, 1)
    setAssestsInfo(data)
  }
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const SelectState = (e) => {
    const foundState = StateData.find((item) => item.state.name === e)
    setLocalGovernment(foundState.state.locals)
  }
  useEffect(() => {
    const foundState = StateData.find(
      (item) => item.state.name === 'Ondo State'
    )
    setLocalGovernment(foundState.state.locals)
  }, [])
  function cleanObject(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key, value]) => {
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            const cleanedNestedObject = cleanObject(value)
            return Object.keys(cleanedNestedObject).length > 0
          }
          return (
            value !== null &&
            value !== '' &&
            !(typeof value === 'object' && !Object.keys(value).length)
          )
        })
        .map(([key, value]) => {
          if (value && typeof value === 'object' && !Array.isArray(value)) {
            return [key, cleanObject(value)]
          }
          return [key, value]
        })
    )
  }
  const onEnroll = (formData) => {
    setFormLoading(true)
    const pictureData = {
      file: profilePicture.file,
      fileName: 'dp',
      uploadType: 'profile',
    }
    const form_data = new FormData()
    Object.keys(pictureData).forEach((key) => {
      form_data.append(key, pictureData[key])
    })
    if (profilePicture.file) {
      fetch(`${process.env.REACT_APP_API_URL}/media`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: form_data,
        redirect: 'follow',
      }).then((res) => {
        if (res.status === 201) {
          res.json().then((picture) => {
            let data = {
              title: formData.title,
              firstName: formData.firstName,
              gender: formData.title === 'Mr' ? 'Male' : 'Female',
              middleName: formData.middleName,
              lastName: formData.lastName,
              email: formData.email.toLowerCase(),
              phone: formData.phone,
              nin: formData.nin,
              tin: formData.tin,
              odTin: formData.odTin,
              dateOfBirth: formData.dateOfBirth,
              stateOfOrigin: formData.state,
              isVip: formData.isVip,
              profilePicture: `${process.env.REACT_APP_API_URL}/media/${picture.path}`,
              identificationDocument: {
                type: idType,
                documentNumber: formData.id_number,
                expiryDate: formData.id_date,
              },
              maritalStatus: formData.maritalStatus,
              residency: {
                address: formData.address,
                nearestBusStop: formData.nearestBusStop,
                lga: formData.lga,
                city: formData.city,
                state: formData.state,
                country: formData.country,
                residentType: houseType,
                yearsOfStay: formData.yearsOfStay,
                numberOfNeighbours: formData.numberOfNeighbours,
              },
            }
            if (formData.spouseName1.length > 1) {
              data = {
                ...data,
                spouse: {
                  firstName: formData.spouseName1,
                  lastName: formData.spouseName2,
                  tin: formData.spouseTin,
                  dateOfBirth: formData.spouseDOB,
                  employerName: formData.spouseBiz,
                },
              }
            }
            if (assestsInfo[0].vehicleId.length > 1) {
              data = {
                ...data,
                assets: assestsInfo,
              }
            }
            if (childInfo[0].firstName.length > 1) {
              data = {
                ...data,
                children: childInfo,
              }
            }
            fetch(`${process.env.REACT_APP_API_URL}/tax-payers`, {
              method: 'post',
              headers: {
                'content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
              body: JSON.stringify(cleanObject(data)),
            })
              .then((res) => {
                if (res.status === 201) {
                  res.json().then((data) => {
                    setFormLoading(false)
                    setEnrollPage('tax')
                  })
                } else if (res.status === 401) {
                  RefreshToken()
                } else {
                  res.json().then((data) => {
                    notify('error', data.message)
                    setFormLoading(false)
                  })
                }
              })
              .catch((err) => {
                notify('error', 'An error ocurred. Unable to reach server')
                setFormLoading(false)
              })
          })
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
    } else {
      let data = {
        title: formData.title,
        firstName: formData.firstName,
        gender: formData.title === 'Mr' ? 'Male' : 'Female',
        middleName: formData.middleName,
        lastName: formData.lastName,
        email: formData.email.toLowerCase(),
        phone: formData.phone,
        nin: formData.nin,
        tin: formData.tin,
        odTin: formData.odTin,
        dateOfBirth: formData.dateOfBirth,
        stateOfOrigin: formData.state,
        isVip: formData.isVip,
        identificationDocument: {
          type: idType,
          documentNumber: formData.id_number,
          expiryDate: formData.id_date,
        },
        maritalStatus: formData.maritalStatus,
        residency: {
          address: formData.address,
          nearestBusStop: formData.nearestBusStop,
          lga: formData.lga,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          residentType: houseType,
          yearsOfStay: formData.yearsOfStay,
          numberOfNeighbours: formData.numberOfNeighbours,
        },
      }
      if (formData.spouseName1.length > 1) {
        data = {
          ...data,
          spouse: {
            firstName: formData.spouseName1,
            lastName: formData.spouseName2,
            tin: formData.spouseTin,
            dateOfBirth: formData.spouseDOB,
            employerName: formData.spouseBiz,
          },
        }
      }
      if (assestsInfo[0].vehicleId.length > 1) {
        data = {
          ...data,
          assets: assestsInfo,
        }
      }
      if (childInfo[0].firstName.length > 1) {
        data = {
          ...data,
          children: childInfo,
        }
      }
      fetch(`${process.env.REACT_APP_API_URL}/tax-payers`, {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(cleanObject(data)),
      })
        .then((res) => {
          if (res.status === 201) {
            res.json().then((data) => {
              setFormLoading(false)
              setEnrollPage('tax')
            })
          } else if (res.status === 401) {
            RefreshToken()
          } else {
            res.json().then((data) => {
              notify('error', data.message)
              setFormLoading(false)
            })
          }
        })
        .catch((err) => {
          notify('error', 'An error ocurred. Unable to reach server')
          setFormLoading(false)
        })
    }
  }
  const onTxEnroll = (formData) => {
    setFormLoading(true)
    const object = {
      tccApplicationsId: formData.tccApplicationsId,
      odTin: formData.odTin,
      tin: formData.tin,
      phone: formData.phone,
      jtbTin: formData.jtbTin,
      fileNo: formData.fileNo,
      applicationNumber: formData.applicationNumber,
      station: formData.station,
      income: formData.income,
      incomeYear: [
        formData.incomeYear1,
        formData.incomeYear2,
        formData.incomeYear3,
      ],
      taxPY: [formData.taxPY1, formData.taxPY2, formData.taxPY3],
      taxRecpY: [formData.taxRecpY1, formData.taxRecpY2, formData.taxRecpY3],
      dLevyY: [formData.dLevyY1, formData.dLevyY2, formData.dLevyY3],
      lucDate: [
        formData.lucDate1,
        formData.lucDate2,
        formData.lucDate3,
        formData.lucDate4,
      ],
      lucAmtY: [formData.lucAmtY1, formData.lucAmtY2, formData.lucAmtY3],
      lucY: [formData.lucY1, formData.lucY2, formData.lucY3, formData.lucY4],
      dLevyRecpY: [
        formData.dLevyRecpY1,
        formData.dLevyRecpY2,
        formData.dLevyRecpY3,
      ],
      appDateY: [
        formData.appDateY1,
        formData.appDateY2,
        formData.appDateY3,
        formData.appDateY4,
      ],
      regDate: [formData.regDate1, formData.regDate2, formData.regDate3],
      CurrentRegDate: formData.CurrentRegDate,
      expDate: formData.expDate,
      remark: formData.remark,
      channel: 'API',
      status: 'Active',
    }
    fetch(`${process.env.REACT_APP_API_URL}/tax-transactions`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(object),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax payer enrolled successfully`)
            setTimeout(() => {
              navigate('/dashboard/tax_payer')
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  const skipTaxEnroll = () => {
    notify('success', `Tax payer enrolled successfully`)
    setTimeout(() => {
      navigate('/dashboard/tax_payer')
    }, 4000)
  }

  return (
    <div className='enrollPayer'>
      <div className='top_button'>
        <button onClick={() => navigate('/dashboard/upload_data/bulkUpload')}>
          Bulk upload data capture
        </button>
      </div>
      <div className='upload_list'>
        <div className='left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Enroll a Payer</h1>
        </div>
        <div className='right'>
          <div className={enrollPage === 'personal' ? 'card active' : ' card'}>
            <BsCheckCircleFill />
            <p>Personal information</p>
          </div>
          <div className='line'></div>
          <div className={enrollPage === 'tax' ? 'card active' : ' card'}>
            <BsCheckCircleFill />
            <p>Tax Information</p>
          </div>
          {/* <div className='line'></div>
          <div className='card'>
            <BsCheckCircleFill />
            <p>Upload document</p>
          </div> */}
        </div>
      </div>
      {enrollPage === 'personal' && (
        <div className='enroll_main'>
          <div
            className='
        '
          ></div>
          {formLoading && (
            <div className='formload'>
              <img src={Loader} alt='' />
            </div>
          )}
          <form className='left' onSubmit={handleSubmit(onEnroll)}>
            <div className='left_top'>
              <RiUser3Line />
              <h2>Personal Information</h2>
            </div>
            <div className='top_form'>
              <div className='top_six'>
                <div className='card'>
                  <p>
                    FIRST NAME{' '}
                    <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                  </p>
                  <input
                    type='text'
                    name='firstName'
                    id=''
                    {...register('firstName', {
                      required: 'First name is required',
                    })}
                  />
                  {errors.firstName && (
                    <h6 className='vError'>{errors.firstName.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>MIDDLE NAME </p>
                  <input
                    type='text'
                    name='middleName'
                    {...register('middleName')}
                  />
                  {errors.middleName && (
                    <h6 className='vError'>{errors.middleName.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>
                    LAST NAME{' '}
                    <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                  </p>
                  <input
                    type='text'
                    name='lastName'
                    {...register('lastName', {
                      required: 'Last name is required',
                    })}
                  />
                  {errors.lastName && (
                    <h6 className='vError'>{errors.lastName.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>
                    TITLE{' '}
                    <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                  </p>
                  <select
                    name='title'
                    id=''
                    {...register('title', {
                      required: 'Title is required',
                    })}
                  >
                    <option value=''>-Select-</option>
                    <option value='Mr'>Mr.</option>
                    <option value='Mrs'>Mrs.</option>
                  </select>
                  {errors.title && (
                    <h6 className='vError'>{errors.title.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>MARITAL STATUS </p>
                  <select
                    name='maritalStatus'
                    id=''
                    {...register('maritalStatus')}
                  >
                    <option value=''>-Select-</option>
                    <option value='Single'>Single</option>
                    <option value='Married'>Married</option>
                  </select>
                  {errors.maritalStatus && (
                    <h6 className='vError'>{errors.maritalStatus.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>DATE OF BIRTH </p>
                  <input
                    type='date'
                    name='dateOfBirth'
                    {...register('dateOfBirth')}
                  />
                  {errors.dateOfBirth && (
                    <h6 className='vError'>{errors.dateOfBirth.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>
                    VIP?{' '}
                    <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                  </p>
                  <select
                    name='isVip'
                    id=''
                    {...register('isVip', {
                      required: 'Vip is required',
                    })}
                  >
                    <option value=''>-Select-</option>
                    <option value='true'>True</option>
                    <option value='false'>False</option>
                  </select>
                  {errors.title && (
                    <h6 className='vError'>{errors.title.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>NIN</p>
                  <input type='tel' name='nin' {...register('nin')} />
                  {errors.nin && (
                    <h6 className='vError'>{errors.nin.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>TIN</p>
                  <input type='tel' name='tin' {...register('tin')} />
                  {errors.tin && (
                    <h6 className='vError'>{errors.tin.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>ODTIN </p>
                  <input type='tel' name='odTin' {...register('odTin')} />
                  {errors.odTin && (
                    <h6 className='vError'>{errors.odTin.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_four'>
                <div className='cards'>
                  <p>
                    CONTACT PHONE NUMBER{' '}
                    <BsAsterisk style={{ color: 'red', fontSize: '8px' }} />
                  </p>
                  <input
                    type='tel'
                    name='phone'
                    {...register('phone', {
                      required: 'phone is required',
                    })}
                  />
                  {errors.phone && (
                    <h6 className='vError'>{errors.phone.message}</h6>
                  )}
                </div>
                <div className='cards'>
                  <p>EMAIL ADDRESS </p>
                  <input type='email' name='email' {...register('email')} />
                  {errors.email && (
                    <h6 className='vError'>{errors.email.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>VALID ID CARD </p>
                  <select
                    name='id_type'
                    onChange={(e) => setIdType(e.target.value)}
                  >
                    <option value=''>Select</option>
                    <option value='National Identity'>National Identity</option>
                    <option value='Voters Card'>Voters Card</option>
                    <option value='Drivers License'>Drivers License</option>
                  </select>
                  {errors.id_type && (
                    <h6 className='vError'>{errors.id_type.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>ID NUMBER </p>
                  <input
                    type='text'
                    name='id_number'
                    {...register('id_number')}
                  />
                  {errors.id_number && (
                    <h6 className='vError'>{errors.id_number.message}</h6>
                  )}
                </div>
                {idType === 'Drivers License' && (
                  <div className='card'>
                    <p>ID EXPIRY DATE </p>
                    <input
                      type='date'
                      name='id_date'
                      {...register('id_date')}
                    />
                    {errors.id_date && (
                      <h6 className='vError'>{errors.id_date.message}</h6>
                    )}
                  </div>
                )}
              </div>
              <div className='address'>
                <p>CURRENT RESIDENTIAL ADDRESS </p>
                <input type='text' name='address' {...register('address')} />
                {errors.address && (
                  <h6 className='vError'>{errors.address.message}</h6>
                )}
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>NEAREST BUSTOP </p>
                  <input
                    type='text'
                    name='nearestBusStop'
                    {...register('nearestBusStop')}
                  />
                  {errors.nearestBusStop && (
                    <h6 className='vError'>{errors.nearestBusStop.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>CITY </p>
                  <input type='text' name='city' {...register('city')} />
                  {errors.city && (
                    <h6 className='vError'>{errors.city.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>STATE </p>
                  <select
                    name='state'
                    id=''
                    {...register('state')}
                    onChange={(e) => {
                      SelectState(e.target.value)
                    }}
                  >
                    <option value=''>-Select-</option>
                    {StateData.map((item) => (
                      <option value={item.state.name} key={item.state.name}>
                        {item.state.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <h6 className='vError'>{errors.state.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>LOCAL GOVT. AREA </p>
                  <select name='lga' id='' {...register('lga')}>
                    <option value=''>Select</option>
                    {localGovernment.map((item) => (
                      <option value={item.name} key={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.lga && (
                    <h6 className='vError'>{errors.lga.message}</h6>
                  )}
                </div>

                <div className='card'>
                  <p>NATIONALITY </p>
                  <select name='country' {...register('country')}>
                    <option value='Nigeria'>Nigeria</option>
                  </select>
                </div>
                <div className='card'>
                  <p>OCCUPATION </p>
                  <input
                    type='text'
                    name='occupation'
                    {...register('occupation')}
                  />
                  {errors.occupation && (
                    <h6 className='vError'>{errors.occupation.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>RESIDENT TYPE </p>
                  <select
                    name='residentType'
                    onChange={(e) => setHouseType(e.target.value)}
                  >
                    <option value=''>Select</option>
                    <option value='owner'>Owner</option>
                    <option value='tenant'>Tenant</option>
                  </select>
                  {errors.residentType && (
                    <h6 className='vError'>{errors.residentType.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>YEARS OF STAY </p>
                  <input
                    type='number'
                    name='yearsOfStay'
                    {...register('yearsOfStay')}
                  />
                  {errors.yearsOfStay && (
                    <h6 className='vError'>{errors.yearsOfStay.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>
                    NO. OF {houseType === 'owner' ? 'TENANTS' : 'NEIGHBOURS'}{' '}
                  </p>
                  <input
                    type='number'
                    name='numberOfNeighbours'
                    {...register('numberOfNeighbours')}
                  />
                  {errors.numberOfNeighbours && (
                    <h6 className='vError'>
                      {errors.numberOfNeighbours.message}
                    </h6>
                  )}
                </div>
              </div>
            </div>
            <div className='left_top'>
              <MdOutlineBusiness />
              <h2>Employer/Business</h2>
            </div>
            <div className='top_form'>
              <div className='top_four'>
                <div className='cards'>
                  <p>BUSINESS NAME</p>
                  <input type='text' name='' id='' />
                </div>
                <div className='cards'>
                  <p>BUSINESS ADDRESS</p>
                  <input type='text' name='' id='' />
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>TYPE OF EMPLOYMENT</p>
                  <input type='text' name='' id='' />
                </div>
                <div className='card'>
                  <p>EMPLOYER EMAIL</p>
                  <input type='email' name='' id='' />
                </div>
                <div className='card'>
                  <p>NUMBER OF EMPLOYEE</p>
                  <input type='number' name='' id='' />
                </div>
              </div>
            </div>
            <div className='left_top'>
              <RiGroupLine />
              <h2>If married, provide spouse and child(ren) details below</h2>
            </div>
            <div className='top_form'>
              <div className='top_six'>
                <div className='card'>
                  <p>Spouse FIRST NAME</p>
                  <input
                    type='text'
                    name='spouseName1'
                    {...register('spouseName1')}
                  />
                </div>
                <div className='card'>
                  <p> Spouse LAST NAME</p>
                  <input
                    type='text'
                    name='spouseName2'
                    {...register('spouseName2')}
                  />
                </div>
                <div className='card'>
                  <p> Spouse DATE OF BIRTH</p>
                  <input
                    type='date'
                    name='spouseDOB'
                    {...register('spouseDOB')}
                  />
                </div>
              </div>
              <div className='top_four'>
                <div className='cards'>
                  <p>EMPLOYER/BUSINESS</p>
                  <input
                    type='text'
                    name='spouseBiz'
                    {...register('spouseBiz')}
                  />
                </div>
                <div className='cards'>
                  <p>Spouse NIN</p>
                  <input
                    type='nmber'
                    name='spouseTin'
                    {...register('spouseTin')}
                  />
                </div>
              </div>
              {/* <div className='address'>
              <p>NUMBER OF CHILDREN</p>
              <select name='' id=''>
                <option value='4'>4</option>
              </select>
            </div> */}
              {childInfo.map((form, index) => (
                <div className='child_add' key={index}>
                  <div className='cards'>
                    <p>CHILD FIRST NAME</p>
                    <input
                      type='text'
                      name='firstName'
                      onChange={(event) => handleChildFormChange(event, index)}
                      defaultValue={form.firstName}
                    />
                  </div>
                  <div className='cards'>
                    <p>CHILD LAST NAME</p>
                    <input
                      type='text'
                      name='lastName'
                      onChange={(event) => handleChildFormChange(event, index)}
                      defaultValue={form.lastName}
                    />
                  </div>
                  <div
                    className='remove-butt'
                    onClick={() => removeChildFields(index)}
                  >
                    remove
                  </div>
                </div>
              ))}
              <div className='child_button'>
                <div className='button' onClick={() => addChildFields()}>
                  <p>Add another Child</p>
                </div>
              </div>
            </div>
            <div className='left_top'>
              <MdOutlineBusiness />
              <h2>Assests Information</h2>
            </div>
            <div className='top_form'>
              {assestsInfo.map((form, index) => (
                <div className='top_six' key={index}>
                  <div className='card'>
                    <p>Vehicle ID</p>
                    <input
                      type='text'
                      name='vehicleId'
                      id=''
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.vehicleId}
                    />
                  </div>
                  <div className='card'>
                    <p>ENGINE NO</p>
                    <input
                      type='text'
                      name='engineNo'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.engineNo}
                    />
                  </div>
                  <div className='card'>
                    <p>CHASIS NO</p>
                    <input
                      type='text'
                      name='chasisNo'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.chasisNo}
                    />
                  </div>
                  <div className='card'>
                    <p>CATEGORY</p>
                    <input
                      type='text'
                      name='category'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.category}
                    />
                  </div>
                  <div className='card'>
                    <p>MAKE</p>
                    <input
                      type='text'
                      name='make'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.make}
                    />
                  </div>
                  <div className='card'>
                    <p>MODEL</p>
                    <input
                      type='text'
                      name='model'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.model}
                    />
                  </div>
                  <div className='card'>
                    <p>type</p>
                    <input
                      type='text'
                      name='type'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.type}
                    />
                  </div>
                  <div className='card'>
                    <p>color</p>
                    <input
                      type='text'
                      name='color'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.color}
                    />
                  </div>
                  <div className='card'>
                    <p>purpose</p>
                    <input
                      type='text'
                      name='purpose'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.purpose}
                    />
                  </div>
                  <div className='card'>
                    <p>capacity</p>
                    <input
                      type='text'
                      name='capacity'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.capacity}
                    />
                  </div>
                  <div className='card'>
                    <p>plate Number</p>
                    <input
                      type='text'
                      name='plateNumber'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.plateNumber}
                    />
                  </div>
                  <div className='card'>
                    <p>owner Name</p>
                    <input
                      type='text'
                      name='ownerName'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.ownerName}
                    />
                  </div>
                  <div className='card'>
                    <p>address</p>
                    <input
                      type='text'
                      name='address'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.address}
                    />
                  </div>
                  <div className='card'>
                    <p>reg Date</p>
                    <input
                      type='text'
                      name='regDate'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.regDate}
                    />
                  </div>
                  <div className='card'>
                    <p>reg Description</p>
                    <input
                      type='text'
                      name='regDescription'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.regDescription}
                    />
                  </div>
                  <div className='card'>
                    <p>year of manufacture</p>
                    <input
                      type='text'
                      name='year'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.year}
                    />
                  </div>
                  <div className='card'>
                    <p>officer</p>
                    <input
                      type='text'
                      name='officer'
                      onChange={(event) => handleAssestFormChange(event, index)}
                      defaultValue={form.officer}
                    />
                  </div>
                  <div className='card'>
                    <p>.</p>
                    <button onClick={removeAssestsFields}>Remove Car</button>
                  </div>
                </div>
              ))}

              <div className='child_button'>
                <div className='button' onClick={() => addAssestsFields()}>
                  <p>Add another Assest</p>
                </div>
              </div>
            </div>
            <div className='submit'>
              <button>
                <p>Submit and proceed</p>
                <BsArrowRight />
              </button>
            </div>
          </form>
          <div className='right'>
            <div className='right_top'>
              <h2>Upload passport</h2>
            </div>
            <div className='image_container'>
              <input
                type='file'
                accept='image/*'
                onChange={onSelectProductImage}
                name='product_img'
                id='files'
              />
              <label htmlFor='files' className='imagee'>
                {imgTru ? (
                  <img src={profilePicture.string} alt='' />
                ) : (
                  <div className='placeholder'>
                    <BsCamera />
                  </div>
                )}
              </label>
            </div>
            <div className='text'>
              <p>
                Must be white background The two ears must be visible Must not
                wear glasses must not smile or laugh
              </p>
            </div>
          </div>
        </div>
      )}
      {enrollPage === 'tax' && (
        <div className='enroll_main'>
          {formLoading && (
            <div className='formload'>
              <img src={Loader} alt='' />
            </div>
          )}
          <form className='left diff' onSubmit={TaxSubmit(onTxEnroll)}>
            <div className='top_form'>
              <div className='taxtrans'>
                <div className='left_top diff2'>
                  <TbReceiptTax />
                  <h2>Tax Information</h2>
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>tccApplicationsId</p>
                  <input
                    type='number'
                    name='tccApplicationsId'
                    id=''
                    {...TaxRegister('tccApplicationsId', {
                      required: 'tccApplicationsId is required',
                    })}
                  />
                  {TaxErrors.tccApplicationsId && (
                    <h6 className='vError'>
                      {TaxErrors.tccApplicationsId.message}
                    </h6>
                  )}
                </div>
                <div className='card'>
                  <p>odTin</p>
                  <input
                    type='number'
                    name='odTin'
                    {...TaxRegister('odTin', {
                      required: 'odTin is required',
                    })}
                  />
                  {TaxErrors.odTin && (
                    <h6 className='vError'>{TaxErrors.odTin.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>tin</p>
                  <input
                    type='number'
                    name='tin'
                    {...TaxRegister('tin', {
                      required: 'tin is required',
                    })}
                  />
                  {TaxErrors.tin && (
                    <h6 className='vError'>{TaxErrors.tin.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>phone</p>
                  <input
                    type='number'
                    name='phone'
                    {...TaxRegister('phone', {
                      required: 'phone is required',
                    })}
                  />
                  {TaxErrors.phone && (
                    <h6 className='vError'>{TaxErrors.phone.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>jtbTin</p>
                  <input
                    type='number'
                    name='jtbTin'
                    {...TaxRegister('jtbTin', {
                      required: 'jtbTin is required',
                    })}
                  />
                  {TaxErrors.jtbTin && (
                    <h6 className='vError'>{TaxErrors.jtbTin.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>fileNo</p>
                  <input
                    type='text'
                    name='fileNo'
                    {...TaxRegister('fileNo', {
                      required: 'fileNo is required',
                    })}
                  />
                  {TaxErrors.fileNo && (
                    <h6 className='vError'>{TaxErrors.fileNo.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>applicationNumber</p>
                  <input
                    type='text'
                    name='applicationNumber'
                    {...TaxRegister('applicationNumber', {
                      required: 'applicationNumber is required',
                    })}
                  />
                  {TaxErrors.applicationNumber && (
                    <h6 className='vError'>
                      {TaxErrors.applicationNumber.message}
                    </h6>
                  )}
                </div>
                <div className='card'>
                  <p>station</p>
                  <input
                    type='text'
                    name='station'
                    {...TaxRegister('station', {
                      required: 'station is required',
                    })}
                  />
                  {TaxErrors.station && (
                    <h6 className='vError'>{TaxErrors.station.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>income</p>
                  <input
                    type='text'
                    name='income'
                    {...TaxRegister('income', {
                      required: 'income is required',
                    })}
                  />
                  {TaxErrors.income && (
                    <h6 className='vError'>{TaxErrors.income.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>incomeYear1</p>
                  <input
                    type='text'
                    name='incomeYear1'
                    id=''
                    {...TaxRegister('incomeYear1', {
                      required: 'incomeYear1 is required',
                    })}
                  />
                  {TaxErrors.incomeYear1 && (
                    <h6 className='vError'>{TaxErrors.incomeYear1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>incomeYear2</p>
                  <input
                    type='text'
                    name='incomeYear2'
                    id=''
                    {...TaxRegister('incomeYear2', {
                      required: 'incomeYear2 is required',
                    })}
                  />
                  {TaxErrors.incomeYear2 && (
                    <h6 className='vError'>{TaxErrors.incomeYear2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>incomeYear3</p>
                  <input
                    type='text'
                    name='incomeYear3'
                    id=''
                    {...TaxRegister('incomeYear3', {
                      required: 'incomeYear3 is required',
                    })}
                  />
                  {TaxErrors.incomeYear3 && (
                    <h6 className='vError'>{TaxErrors.incomeYear3.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>taxPY1</p>
                  <input
                    type='text'
                    name='taxPY1'
                    id=''
                    {...TaxRegister('taxPY1', {
                      required: 'taxPY1 is required',
                    })}
                  />
                  {TaxErrors.taxPY1 && (
                    <h6 className='vError'>{TaxErrors.taxPY1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>taxPY2</p>
                  <input
                    type='text'
                    name='taxPY2'
                    id=''
                    {...TaxRegister('taxPY2', {
                      required: 'taxPY2 is required',
                    })}
                  />
                  {TaxErrors.taxPY2 && (
                    <h6 className='vError'>{TaxErrors.taxPY2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>taxPY3</p>
                  <input
                    type='text'
                    name='taxPY3'
                    id=''
                    {...TaxRegister('taxPY3', {
                      required: 'taxPY3 is required',
                    })}
                  />
                  {TaxErrors.taxPY3 && (
                    <h6 className='vError'>{TaxErrors.taxPY3.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>taxRecpY1</p>
                  <input
                    type='number'
                    name='taxRecpY1'
                    id=''
                    {...TaxRegister('taxRecpY1', {
                      required: 'taxRecpY1 is required',
                    })}
                  />
                  {TaxErrors.taxRecpY1 && (
                    <h6 className='vError'>{TaxErrors.taxRecpY1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>taxRecpY2</p>
                  <input
                    type='number'
                    name='taxRecpY2'
                    id=''
                    {...TaxRegister('taxRecpY2', {
                      required: 'taxRecpY2 is required',
                    })}
                  />
                  {TaxErrors.taxRecpY2 && (
                    <h6 className='vError'>{TaxErrors.taxRecpY2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>taxRecpY3</p>
                  <input
                    type='number'
                    name='taxRecpY3'
                    id=''
                    {...TaxRegister('taxRecpY3', {
                      required: 'taxRecpY3 is required',
                    })}
                  />
                  {TaxErrors.taxRecpY3 && (
                    <h6 className='vError'>{TaxErrors.taxRecpY3.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>dLevyY1</p>
                  <input
                    type='text'
                    name='dLevyY1'
                    id=''
                    {...TaxRegister('dLevyY1', {
                      required: 'dLevyY1 is required',
                    })}
                  />
                  {TaxErrors.dLevyY1 && (
                    <h6 className='vError'>{TaxErrors.dLevyY1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>dLevyY2</p>
                  <input
                    type='text'
                    name='dLevyY2'
                    id=''
                    {...TaxRegister('dLevyY2', {
                      required: 'dLevyY2 is required',
                    })}
                  />
                  {TaxErrors.dLevyY2 && (
                    <h6 className='vError'>{TaxErrors.dLevyY2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>dLevyY3</p>
                  <input
                    type='text'
                    name='dLevyY3'
                    id=''
                    {...TaxRegister('dLevyY3', {
                      required: 'dLevyY3 is required',
                    })}
                  />
                  {TaxErrors.dLevyY3 && (
                    <h6 className='vError'>{TaxErrors.dLevyY3.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six diff'>
                <div className='card'>
                  <p>lucDate1</p>
                  <input
                    type='date'
                    name='lucDate1'
                    id=''
                    {...TaxRegister('lucDate1', {
                      required: 'lucDate1 is required',
                    })}
                  />
                  {TaxErrors.lucDate1 && (
                    <h6 className='vError'>{TaxErrors.lucDate1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucDate2</p>
                  <input
                    type='date'
                    name='lucDate2'
                    id=''
                    {...TaxRegister('lucDate2', {
                      required: 'lucDate2 is required',
                    })}
                  />
                  {TaxErrors.lucDate2 && (
                    <h6 className='vError'>{TaxErrors.lucDate2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucDate3</p>
                  <input
                    type='date'
                    name='lucDate3'
                    id=''
                    {...TaxRegister('lucDate3', {
                      required: 'lucDate3 is required',
                    })}
                  />
                  {TaxErrors.lucDate3 && (
                    <h6 className='vError'>{TaxErrors.lucDate3.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucDate4</p>
                  <input
                    type='date'
                    name='lucDate4'
                    id=''
                    {...TaxRegister('lucDate4', {
                      required: 'lucDate4 is required',
                    })}
                  />
                  {TaxErrors.lucDate4 && (
                    <h6 className='vError'>{TaxErrors.lucDate4.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>lucAmtY1</p>
                  <input
                    type='number'
                    name='lucAmtY1'
                    id=''
                    {...TaxRegister('lucAmtY1', {
                      required: 'lucAmtY1 is required',
                    })}
                  />
                  {TaxErrors.lucAmtY1 && (
                    <h6 className='vError'>{TaxErrors.lucAmtY1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucAmtY2</p>
                  <input
                    type='number'
                    name='lucAmtY2'
                    id=''
                    {...TaxRegister('lucAmtY2', {
                      required: 'lucAmtY2 is required',
                    })}
                  />
                  {TaxErrors.lucAmtY2 && (
                    <h6 className='vError'>{TaxErrors.lucAmtY2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucAmtY3</p>
                  <input
                    type='number'
                    name='lucAmtY3'
                    id=''
                    {...TaxRegister('lucAmtY3', {
                      required: 'lucAmtY3 is required',
                    })}
                  />
                  {TaxErrors.lucAmtY3 && (
                    <h6 className='vError'>{TaxErrors.lucAmtY3.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six diff'>
                <div className='card'>
                  <p>lucY1</p>
                  <input
                    type='number'
                    name='lucY1'
                    id=''
                    {...TaxRegister('lucY1', {
                      required: 'lucY1 is required',
                    })}
                  />
                  {TaxErrors.lucY1 && (
                    <h6 className='vError'>{TaxErrors.lucY1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucY2</p>
                  <input
                    type='number'
                    name='lucY2'
                    id=''
                    {...TaxRegister('lucY2', {
                      required: 'lucY2 is required',
                    })}
                  />
                  {TaxErrors.lucY2 && (
                    <h6 className='vError'>{TaxErrors.lucY2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucY3</p>
                  <input
                    type='number'
                    name='lucY3'
                    id=''
                    {...TaxRegister('lucY3', {
                      required: 'lucY3 is required',
                    })}
                  />
                  {TaxErrors.lucY3 && (
                    <h6 className='vError'>{TaxErrors.lucY3.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>lucY4</p>
                  <input
                    type='number'
                    name='lucY4'
                    id=''
                    {...TaxRegister('lucY4', {
                      required: 'lucY4 is required',
                    })}
                  />
                  {TaxErrors.lucY4 && (
                    <h6 className='vError'>{TaxErrors.lucY4.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>dLevyRecpY1</p>
                  <input
                    type='number'
                    name='dLevyRecpY1'
                    id=''
                    {...TaxRegister('dLevyRecpY1', {
                      required: 'dLevyRecpY1 is required',
                    })}
                  />
                  {TaxErrors.dLevyRecpY1 && (
                    <h6 className='vError'>{TaxErrors.dLevyRecpY1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>dLevyRecpY2</p>
                  <input
                    type='number'
                    name='dLevyRecpY2'
                    id=''
                    {...TaxRegister('dLevyRecpY2', {
                      required: 'dLevyRecpY2 is required',
                    })}
                  />
                  {TaxErrors.dLevyRecpY2 && (
                    <h6 className='vError'>{TaxErrors.dLevyRecpY2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>dLevyRecpY3</p>
                  <input
                    type='number'
                    name='dLevyRecpY3'
                    id=''
                    {...TaxRegister('dLevyRecpY3', {
                      required: 'dLevyRecpY3 is required',
                    })}
                  />
                  {TaxErrors.dLevyRecpY3 && (
                    <h6 className='vError'>{TaxErrors.dLevyRecpY3.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six diff'>
                <div className='card'>
                  <p>appDateY1</p>
                  <input
                    type='number'
                    name='appDateY1'
                    id=''
                    placeholder='2001'
                    {...TaxRegister('appDateY1', {
                      required: 'appDateY1 is required',
                    })}
                  />
                  {TaxErrors.appDateY1 && (
                    <h6 className='vError'>{TaxErrors.appDateY1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>appDateY2</p>
                  <input
                    type='number'
                    name='appDateY2'
                    placeholder='2001'
                    id=''
                    {...TaxRegister('appDateY2', {
                      required: 'appDateY2 is required',
                    })}
                  />
                  {TaxErrors.appDateY2 && (
                    <h6 className='vError'>{TaxErrors.appDateY2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>appDateY3</p>
                  <input
                    type='number'
                    name='appDateY3'
                    placeholder='2001'
                    id=''
                    {...TaxRegister('appDateY3', {
                      required: 'appDateY3 is required',
                    })}
                  />
                  {TaxErrors.appDateY3 && (
                    <h6 className='vError'>{TaxErrors.appDateY3.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>appDateY4</p>
                  <input
                    type='number'
                    name='appDateY4'
                    placeholder='2001'
                    id=''
                    {...TaxRegister('appDateY4', {
                      required: 'appDateY4 is required',
                    })}
                  />
                  {TaxErrors.appDateY4 && (
                    <h6 className='vError'>{TaxErrors.appDateY4.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>regDate1</p>
                  <input
                    type='date'
                    name='regDate1'
                    id=''
                    {...TaxRegister('regDate1', {
                      required: 'regDate1 is required',
                    })}
                  />
                  {TaxErrors.regDate1 && (
                    <h6 className='vError'>{TaxErrors.regDate1.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>regDate2</p>
                  <input
                    type='date'
                    name='regDate2'
                    id=''
                    {...TaxRegister('regDate2', {
                      required: 'regDate2 is required',
                    })}
                  />
                  {TaxErrors.regDate2 && (
                    <h6 className='vError'>{TaxErrors.regDate2.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>regDate3</p>
                  <input
                    type='date'
                    name='regDate3'
                    id=''
                    {...TaxRegister('regDate3', {
                      required: 'regDate3 is required',
                    })}
                  />
                  {TaxErrors.regDate3 && (
                    <h6 className='vError'>{TaxErrors.regDate3.message}</h6>
                  )}
                </div>
              </div>
              <div className='top_six'>
                <div className='card'>
                  <p>CurrentRegDate</p>
                  <input
                    type='date'
                    name='CurrentRegDate'
                    id=''
                    {...TaxRegister('CurrentRegDate', {
                      required: 'CurrentRegDate is required',
                    })}
                  />
                  {TaxErrors.CurrentRegDate && (
                    <h6 className='vError'>
                      {TaxErrors.CurrentRegDate.message}
                    </h6>
                  )}
                </div>
                <div className='card'>
                  <p>expDate</p>
                  <input
                    type='date'
                    name='expDate'
                    id=''
                    {...TaxRegister('expDate', {
                      required: 'expDate is required',
                    })}
                  />
                  {TaxErrors.expDate && (
                    <h6 className='vError'>{TaxErrors.expDate.message}</h6>
                  )}
                </div>
                <div className='card'>
                  <p>remark</p>
                  <input
                    type='text'
                    name='remark'
                    id=''
                    {...TaxRegister('remark', {
                      required: 'remark is required',
                    })}
                  />
                  {TaxErrors.remark && (
                    <h6 className='vError'>{TaxErrors.remark.message}</h6>
                  )}
                </div>
              </div>
            </div>
            <div className='submit'>
              <button>
                <p>Submit</p>
                <BsArrowRight />
              </button>
            </div>
          </form>
          <div className='submit'>
            <button onClick={skipTaxEnroll}>
              <p className=''>Skip Tax Enrollment and Submit</p>{' '}
              <BsArrowRight />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default EnrollPayer
