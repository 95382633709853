import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../Utility/Context/Context'
import API from '../../Utility/API/API'
import './Dashboard.scss'
import Sidebar from './Component/Sidebar/Sidebar'
import Topbar from './Component/TopBar/Topbar'
import Home from './Dashboard_pages/Home/Home'
import AdminUser from './Dashboard_pages/Admin_user/Admin_user'
import TaxPayer from './Dashboard_pages/Tax_payer/TaxPayer'
import ETax from './Dashboard_pages/E_Tax/E_Tax'
import InactivePayer from './Dashboard_pages/Inactive_payer/InactivePayer'
import Corporate from './Dashboard_pages/Corporate/Corporate'
import TaxTransaction from './Dashboard_pages/Tax-transaction/TaxTransaction'
import AddUser from './Component/AddUser_Modal/Add_user'
import UserSearch from './Component/UserSearch_Modal/UserSearch'
import RestrictionModal from './Component/RestrictionModal/RestrictionModal'
import DataModal from './Component/DataModal/DataModal'
import UploadData from './Dashboard_pages/UploadData/UploadData'
import UploadEtax from './Dashboard_pages/UploadEtax/UploadEtax'
import EnrollPayer from './Dashboard_pages/EnrollPayer/EnrollPayer'
import UserPage from './Dashboard_pages/UserPage/UserPage'
import CorporateUser from './Dashboard_pages/CorporateUser/CorporateUser'
import AdminSingleUser from './Dashboard_pages/Admin_single_user/Admin_single_user'
import Loader from '../../Images/loader.gif'
import BulkResponse from './Dashboard_pages/BulkResponse/BulkResponse'
import Edit from './Dashboard_pages/Tax_payer/Edit'
import IndividualTransactions from './Dashboard_pages/Tax-transaction/IndividualTransactions'
import EnrollTaxtTrans from './Dashboard_pages/EnrollTaxtTrans/EnrollTaxtTrans'
import UserTransaction from './Dashboard_pages/UserPage/UserTransaction'
import PaymentTransaction from './Dashboard_pages/PaymentTransactions/PaymentTransaction'
import IndividualPayment from './Dashboard_pages/PaymentTransactions/IndividualPayment'
import CreatePayment from './Dashboard_pages/PaymentTransactions/CreatePayment'
import EditPayment from './Dashboard_pages/PaymentTransactions/EditPayment'
import DocumentFile from './Dashboard_pages/Tax_payer/Document'
import EnrollCop from './Dashboard_pages/Enrol-Cop/EnrollCop'
import IndividualCorporate from './Dashboard_pages/CorporateUser/IndividualCorporate'
import EditCorporate from './Dashboard_pages/CorporateUser/EditCorporate'
import CreateCorporate from './Dashboard_pages/CorporateUser/CreateCorporate'
import TaxpayerDrill from './Component/TaxpayerDrill/TaxpayerDrill'
import DataAdmin from './Dashboard_pages/DataAdmin/DataAdmin'
import NewHome from './Dashboard_pages/NewHome/NewHome'
import SearchResult from './Dashboard_pages/SearchResult/SearchResult'
import LinkData from './Component/LinkData/LinkData'
import GeneralData from './Dashboard_pages/GeneralData/GeneralData'
import Receipts from './Dashboard_pages/Reciept/Receipts'
import Receipt from './Dashboard_pages/Reciept/Receipt'
import FileData from './Dashboard_pages/GeneralData/FileData'
import IdleTimerContainer from '../../CustomHooks/IdleTimer'
import AddSocialMedia from './Component/AddUser_Modal/AddSocialMedia'
import AuditTrail from './Dashboard_pages/AuditTrail/AuditTrail'
import ReceiptPdf from './Dashboard_pages/Reciept/ReceiptPDF'
import PresumptiveTax from './Dashboard_pages/Reciept/PresumptiveTax'

function Dashboard({ notify, logOut }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const id = sessionStorage.getItem('AdminIdy')
  const navigate = useNavigate()
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const { page } = useParams()
  const {
    addUser,
    addSocial,
    userSearch,
    restriction,
    dataModal,
    taxpayerDrill,
    showLinkData,
  } = useGlobalContext()
  const [loginData, setLoginData] = useState({})
  const [loading, setLoading] = useState(true)
  const [IsUpload, setIsUpload] = useState(false)
  const [IsEdit, setIsEdit] = useState(false)
  const [fixVIP, setFixVIP] = useState(false)

  useEffect(() => {
    API.FetchUser(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          sessionStorage.setItem('userData', JSON.stringify(data))
          setLoginData(data)
          setIsUpload(
            data.role === 'admin' ||
              data.role === 'data admin' ||
              data.role === 'Security admin'
          )
          setIsEdit(
            data.role === 'admin' ||
              data.role === 'security admin' ||
              data.role === 'data admin'
          )
          setFixVIP(
            data.role === 'security admin' || data.role === 'executive user'
          )
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const dashboardPage = () => {
    switch (page) {
      case 'analytics':
        return <Home notify={notify} IsUpload={IsUpload} />
      case 'home':
        return <NewHome notify={notify} IsUpload={IsUpload} />
      case 'search-result':
        return (
          <SearchResult
            fixVIP={fixVIP}
            loginData={loginData}
            IsUpload={IsUpload}
          />
        )
      case 'admin_user':
        return <AdminUser notify={notify} />
      case 'audit-trail':
        return <AuditTrail notify={notify} />
      case 'inactive_payer':
        return <InactivePayer />
      case 'upload_data':
        return <UploadData notify={notify} loginData={loginData} />

      case 'enroll_Transaction':
        return <EnrollTaxtTrans notify={notify} />
      case 'admin_users':
        return <AdminSingleUser notify={notify} loginData={loginData} />
      case 'bulk_response':
        return <BulkResponse notify={notify} />
      case 'tax_transactions':
        return <IndividualTransactions notify={notify} />
      case 'user_transaction':
        return <UserTransaction notify={notify} />
      //Tax Payer sections
      case 'enroll_payer':
        return <EnrollPayer notify={notify} loginData={loginData} />
      case 'tax_payer':
        return (
          <TaxPayer loginData={loginData} IsUpload={IsUpload} fixVIP={fixVIP} />
        )
      case 'tax_payers':
        return (
          <UserPage
            notify={notify}
            IsUpload={IsUpload}
            IsEdit={IsEdit}
            fixVIP={fixVIP}
          />
        )
      case 'tax_payers_edit':
        return <Edit notify={notify} />
      case 'tax_payerSummary':
        return <DocumentFile notify={notify} />
      //Corporate Section
      case 'corporate':
        return (
          <Corporate
            loginData={loginData}
            IsUpload={IsUpload}
            fixVIP={fixVIP}
          />
        )
      case 'corporate_page':
        return <CorporateUser IsUpload={IsUpload} />
      case 'enroll_corporate':
        return <CreateCorporate notify={notify} loginData={loginData} />
      case 'edit_corporate':
        return <EditCorporate notify={notify} fixVIP={fixVIP} />
      case 'corporate_single':
        return <IndividualCorporate IsEdit={IsEdit} />
      //ETAX Section
      case 'e_tax':
        return <ETax loginData={loginData} IsUpload={IsUpload} />
      case 'upload_etax':
        return <UploadEtax notify={notify} loginData={loginData} />
      //Payment Transactions Section
      case 'transactions_category':
        return <PaymentTransaction notify={notify} IsUpload={IsUpload} />
      case 'payment_transaction':
        return <IndividualPayment notify={notify} IsEdit={IsEdit} />
      case 'record_payment':
        return <CreatePayment notify={notify} loginData={loginData} />
      case 'edit_payment':
        return <EditPayment notify={notify} IsEdit={IsEdit} />
      //Tax Transactions Section
      case 'tax_transaction':
        return <TaxTransaction IsUpload={IsUpload} />
      case 'file-data':
        return <FileData notify={notify} />
      case 'general_data':
        return <GeneralData IsUpload={IsUpload} notify={notify} />
      case 'receipts':
        return <Receipts IsUpload={IsUpload} />
      case 'receipt':
        return <Receipt IsUpload={IsUpload} />
      case 'receipt-pdf':
        return <ReceiptPdf IsUpload={IsUpload} />
      case 'presumptive-tax':
        return <PresumptiveTax notify={notify} />
      // Data Admin
      case 'data-admin':
        return <DataAdmin />

      default:
        return <h1>No page to show</h1>
    }
  }
  return loading ? (
    <div className='loaders'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='main_dashboard'>
      <IdleTimerContainer />
      <div className={showLinkData ? 'modal' : 'modal kil active hey'}>
        <LinkData notify={notify} />
      </div>
      <div className={taxpayerDrill ? 'modal' : 'modal kil active'}>
        <TaxpayerDrill />
      </div>
      <div className={dataModal ? 'modal ' : 'modal active'}>
        <DataModal />
      </div>
      <div className={restriction ? 'modal ' : 'modal active'}>
        <RestrictionModal />
      </div>
      <div className={userSearch ? 'modal ' : 'modal active'}>
        <UserSearch />
      </div>
      <div className={addUser ? 'modal ' : 'modal active'}>
        <AddUser notify={notify} fixVIP={fixVIP} />
      </div>
      <div className={addSocial ? 'modal ' : 'modal active'}>
        <AddSocialMedia notify={notify} />
      </div>
      <Topbar loginData={loginData} />
      <div className='main_body'>
        <Sidebar page={page} logOut={logOut} loginData={loginData} />
        <div className='main_container'>{dashboardPage()}</div>
      </div>
    </div>
  )
}

export default Dashboard
