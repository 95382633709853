import React, { useRef, useState } from 'react'
import Logo from '../../Images/logo.png'
import Loader from '../../Images/loader.gif'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

function ResetPassword({ Login, notify }) {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch('password', '')
  const [searchParams] = useSearchParams()
  const onLogin = (formData) => {
    setLoading(true)
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/auth/reset-password?token=${searchParams.get('token')}`,
      {
        method: 'post',
        headers: {
          'content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: formData.password,
        }),
      }
    )
      .then((res) => {
        if (res.status === 204) {
          notify('success', 'Password Changed')
          setLoading(false)
          setTimeout(() => {
            window.location.replace('/')
          }, 2000)
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setLoading(false)
          })
        }
      })

      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setLoading(false)
        // setFormLoading(false)
      })
  }
  return (
    <div className='login'>
      <div className='center'>
        <div className='left'>
          <div className='card'>
            <h3> Welcome to Ondo State</h3>
            <h1>Electronic Data Management System (EDMS/BIG DATA)</h1>
            <h5>
              Please note that this system comes with a restricted access. Any
              unauthorised access to this system will be denied.{' '}
            </h5>
            <div className='det'>
              <p>For futher enquiries, contact us at ODRIS:</p>
              <p>0818-839-700 | 0902 847 7004 | 0810 486 4804. </p>
            </div>
          </div>
        </div>
        <div className='right'>
          <div className='log'>
            <img src={Logo} alt='' />
          </div>
          <form className='form' action='' onSubmit={handleSubmit(onLogin)}>
            <div className='form_head'>
              <div className='cav'>
                <h1>Reset password</h1>
              </div>
            </div>
            <div className='form_body'>
              <div className='card'>
                <input
                  type='password'
                  placeholder='Password'
                  name='password'
                  {...register('password', {
                    required: 'Provide a password',
                    minLength: {
                      value: 7,
                      message: 'Password must have at least 8 characters',
                    },
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/,
                      message:
                        'Passwords have to contain at least one uppercase and one lower case character and a number',
                    },
                  })}
                />
                {errors.password && (
                  <h6 className='vError'>{errors.password.message}</h6>
                )}
              </div>
              <div className='card'>
                <input
                  type='password'
                  placeholder='Confirm password.'
                  {...register('password2', {
                    required: 'You must re-enter your password',
                    validate: (value) =>
                      value === password.current ||
                      'The passwords do not match',
                  })}
                />
                {errors.password2 && (
                  <h6 className='vError'>{errors.password2.message}</h6>
                )}
              </div>
            </div>

            {loading ? (
              <div className='loaderx'>
                <img src={Loader} alt='' />
              </div>
            ) : (
              <div className='form_bottom'>
                <button>
                  <p>Change Password</p>
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
